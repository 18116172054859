import styled from "styled-components";
import Container from "../containers/Container";

export const MenuOpenStyle = styled.div`
   width: 100vw;
   height: 90%;
   position: fixed;
   top: 0;
   left: 0;
   padding-top: 1em;
   transition: opacity 620ms linear;
   display: flex;
   flex-direction: column;
   display: none;
   z-index: -10000;

   &.open {
      z-index: 10000;
      opacity: 1;
      transition: z-index 10ms ease, opacity 620ms linear;
      display: flex;
   }
   &.close {
      z-index: -10;
      opacity: 0;
      transition: z-index 1020ms ease, opacity 620ms linear;
      display: none;
   }

   background: ${(props) => props.theme.primary};

   @media screen and (min-width: 600px) {
      display: none;
   }
`;

export const ContentMenuHead = styled(Container)`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1em;
   position: relative;

   & .deskOption,
   & .mobileOption {
      display: none;
   }

   & .logoNav {
      padding-left: 0;

      & img {
         width: 120px;
      }
   }

   &.open {
      // transform: translate(0);
      transition: transform 420ms ease, opacity 620ms linear;
      opacity: 1;
   }
   &.close {
      // transform: translateX(-100%);
      opacity: 0;
      transition: transform 420ms ease, opacity 620ms linear;
   }

   &:after {
      content: "";
      width: calc(100vw + 1.5em);
      border-bottom: 1px solid black;
      position: absolute;
      bottom: -1em;
      left: -1em;
   }

   @media screen and (max-width: 600px) {
      // incio de desk
      & .mobileOption {
         display: block;
         padding: 0;
         background-color: transparent;
      }
      & .logoNav {
         padding-left: 0;
         & img {
            width: 70px;
         }
      }
   }
`;

export const ContentMenuList = styled(Container)`
   display: flex;
   flex-direction: column;
   align-items: baseline;
   height: 100%;
   position: relative;

   &.open {
      transform: translate(0);
      transition: transform 420ms ease, opacity 620ms linear;
   }
   &.close {
      transform: translateX(-100%);
      transition: transform 420ms ease, opacity 620ms linear;
   }

   & a {
      display: inline;
      width: auto;
      text-align: left;
      padding: 0.5em 1em;
      margin-top: 0.4em;
      text-transform: uppercase;

      &.isLogin {
         background: ${(props) => props.theme.background};
         color: ${(props) => props.theme.primary};
      }
   }
`;
