import styled from "styled-components";

export const ContentProfileStyled = styled.div`
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 300px;
   margin: 2em 0;

   @media screen and (max-width: 600px) {
      justify-content: flex-start;
   }
`;

export const ContentData = styled.div`
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
   width: 90%;

   @media screen and (max-width: 1000px) {
      width: 100%;
   }
`;

export const ProfileCol = styled.div`
   width: 25%;
   margin 2em 0;
   padding: 0.5em;
   box-sizing: border-box;

   
   

   @media screen and (max-width: 1000px) {
      &.large {
         width: 40%
      }
   }
   @media screen and (max-width: 600px) {
      width: 33.33%;
      margin: 1em 0;

      &.large {
         width: 100%
      }
   }
`;

export const ColTitle = styled.p`
   font-size: 1em;
   color: ${(props) => props.theme.primary};

   @media screen and (max-width: 1000px) {
      font-size: 0.9em;
   }
`;

export const ColText = styled.p`
   font-size: 1.5em;

   &.bold {
      font-weight: 400;
   }

   @media screen and (max-width: 1000px) {
      font-size: 1.2em;
   }
`;

export const ProfileTitle = styled.p`
   font-size: 2.5em;
   text-align: center;
   font-weight: 500;
`;
export const Separator = styled.div`
   margin: 1em 0;

   & .bar {
      width: 120px;
      height: 4px;
      background-color: ${(props) => props.theme.primary};
      border-radius: 3px;
   }
`;

export const ContentButtons = styled.div`
   display: flex;
   width: 100%;
   justify-content: center;
   align-items: center;

   & button {
      margin: 0 1em;
   }
`;

export const ContentMember = styled.div`
   width: 60%;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   @media screen and (max-width: 1000px) {
      width: 80%;
   }
`;

export const DataMemberCol = styled.div`
   width: 45%;
   display: flex;
   justify-content: space-between;

   @media screen and (max-width: 600px) {
      width: 100%;
   }

   & p {
      font-weight: 200;
     
      &.bold {
         font-weight: 400;
         margin-right: 1em;
      }
   }
`;
