import React from "react";
import styled from "styled-components";

export default function Checker({title, name, type, value, change, defaultChecked}) {
   return (
      <>
         <CheckInput
            type={type}
            value={value}
            name={name}
            id={name + value}
            defaultChecked={defaultChecked}
            onChange={() => {
               if (change) {
                  change();
               }
            }}
         />
         <LabelInput htmlFor={name + value}>
            <span className="checkBox">&#10004;</span>
            {title}
         </LabelInput>
      </>
   );
}

Checker.defaultProps = {
   type: "checkbox",
   value: "value",
   title: "title",
};

const CheckInput = styled.input`
   display: none;

   & + label {
      display: inline-flex;
      align-items: center;
   }

   &:checked {
      & + label {
         & .checkBox {
            background: ${(props) => props.theme.background};
            transition: all 420ms ease;
         }
      }
   }
`;

const LabelInput = styled.label`
   color: ${(props) => props.theme.background};
   position: relative;
   user-select: none;
   margin-right: 1em;

   & .checkBox {
      width: 1em;
      height: 1em;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-right: 0.5em;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.background};

      transition: all 420ms ease;
      color: ${(props) => props.theme.primary};
   }
`;
