import React from "react";
import {AboutSection, TextAbout, MediaWe} from "./AboutStyles";
import Container from "../../../../containers/Container";
import AnimateImg from "../../../../containers/AnimateImg";

export default function WeAre() {
   return (
      <AboutSection>
         <Container>
            <TextAbout className="restricted">
               En <b>Freeticket</b>&reg; estamos innovando constantemente y nos gusta que disfrutes de <b>los mejores eventos de comedia</b>{" "}
               aún si no estás presente. Es por ello que hemos lanzado una plataforma totalmente nueva para que disfrutes de los mejores
               shows y comediantes desde la comodidad de tu espacio. <b>¡Disfrutalo estés donde estés!</b>
            </TextAbout>
            <MediaWe>
               <AnimateImg src={require("../../../../assets/img/multi_device.png")} alt="multi_device_freeticket" className="imgAbout" />
            </MediaWe>
         </Container>
      </AboutSection>
   );
}
