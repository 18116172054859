/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {useLocation, useHistory} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import {AppText, ContentCard, BigText, AuthButtonLink, ContentButtons} from "../../../styles/AuthStyles";
import CardModalAuth from "../../../containers/CardModalAuth";
import useQuery from "../../../hooks/useQuery";

// Selccionar método de pago

export default function PaysMethodView() {
   const location = useLocation();
   const history = useHistory();
   let backModal = useQuery().get("backModal");

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               if (backModal) {
                  history.replace(`?modal=${backModal}`);
               }
            }}
         >
            <ContentCard>
               <BigText>Adquiere tu membresía</BigText>

               <AppText>Selecciona el método de pago y llena los campos de acuerdo a la solicitud.</AppText>

               <ContentButtons className="grid">
                  <div className="column">
                     <AuthButtonLink to="?modal=paypal">PAYPAL</AuthButtonLink>
                     <AuthButtonLink to="?modal=cards" state={{next: location.state ? location.state.next : null}}>
                        TARJETA BANCARIA
                     </AuthButtonLink>
                  </div>
                  <div className="column">
                     <AuthButtonLink to="?modal=transfer">TRANSFERENCIA</AuthButtonLink>
                     <AuthButtonLink to="?modal=membership-code">CÓDIGO DE REGALO</AuthButtonLink>
                  </div>
               </ContentButtons>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
