import React from "react";
import {BigText, AuthButton, InputLabel, GenreContent} from "../../../../styles/AuthStyles";
import Input from "../../../../components/auth/Input";
import Select from "../../../../components/auth/Select";
// import {useHistory} from "react-router-dom";
import Checker from "../../../../components/auth/Checker";
import {useSelector, useDispatch} from "react-redux";
import {UPDATE_PROFILE} from "../../../../ContextProvider/Types";
import {updateUserDataAction} from "../../../../ContextProvider/Actions";

export default function UpdateForm({listener}) {
   const userProfileData = useSelector((state) => state.auth);
   const dispatch = useDispatch();

   const updateProfile = (e) => {
      e.preventDefault();
      let nextUserData = {
         first_name: e.target.first_name.value,
         last_name: e.target.last_name.value,
         email: e.target.email.value,
         username: e.target.username.value,
         genre: e.target.genre.value,
         birthdate: e.target.birthdate.value,
         phone: e.target.phone.value,
         country_id: Number(e.target.country.value),
         city_id: Number(e.target.country.value) === 1 ? 1 : 3,
         role_id: userProfileData.id_rol,
         user_id: userProfileData.id,
      };
      dispatch({type: UPDATE_PROFILE, payload: {...nextUserData}});
      dispatch(updateUserDataAction());
      listener();
   };

   return (
      <div style={{width: "100%", textAlign: "center"}}>
         <BigText>Actualizar perfil</BigText>
         <form onSubmit={updateProfile}>
            {React.Children.toArray(
               InputList.map((newInput) => {
                  return (
                     <Input
                        type={newInput.type}
                        defaultValue={userProfileData[newInput.name]}
                        name={newInput.name}
                        placeholder={newInput.placeholder}
                        required={true}
                     />
                  );
               }),
            )}
            <Select
               defaultValue={userProfileData.country_id}
               placeholder={{value: "0", name: "Selecciona tu país"}}
               options={[
                  {name: "Colombia", value: 2},
                  {name: "México", value: 1},
               ]}
               name="country"
            />
            <GenreContent>
               <InputLabel>Género:</InputLabel>
               <Checker type="radio" name="genre" value="male" title="H" defaultChecked={userProfileData.genre === "male"} />
               <Checker type="radio" name="genre" value="female" title="M" defaultChecked={userProfileData.genre === "female"} />
            </GenreContent>
            {/* {errorList && <AppText color="crimson">{errorList}</AppText>} */}
            <AuthButton>Actualizar</AuthButton>
         </form>
      </div>
   );
}

const InputList = [
   {
      name: "first_name",
      type: "text",
      placeholder: "Nombre",
   },
   {
      name: "last_name",
      type: "text",
      placeholder: "Apellidos",
   },
   {
      name: "username",
      type: "text",
      placeholder: "Usuario",
   },
   {
      name: "birthdate",
      type: "date",
      placeholder: "Fecha de nacimiento",
   },
   {
      name: "phone",
      type: "tel",
      placeholder: "Teléfono",
   },
   {
      name: "email",
      type: "email",
      placeholder: "Correo electrónico",
   },
];
