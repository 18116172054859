import React from "react";
import {AboutSection} from "./AboutStyles";
import styled from "styled-components";
import Container from "../../../../containers/Container";
import {ButtonLink} from "../../../../styles/ButtonStyles";

export default function DiscoverCalendar() {
   return (
      <DiscoverContent background="yellow">
         <Container className="content">
            <p className="text">Conoce nuestro calendario de eventos.</p>
            <ButtonLink to="/events" className="button">
               VER EVENTOS
            </ButtonLink>
         </Container>
      </DiscoverContent>
   );
}

const DiscoverContent = styled(AboutSection)`
   background-image: url(${require("../../../../assets/img/event_img_3.jpg")});
   background-color: rgba(0, 0, 0, 0.5);
   background-size: cover;
   background-position: center;
   background-blend-mode: darken;
   padding: 5em 0;

   & .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & .button {
         padding: 0.8em 2.5em;
         font-weight: 400;
      }

      & .text {
         font-size: 1.5em;
         text-align: center;
         margin-bottom: 1em;
      }
   }
`;
