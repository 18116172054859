/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from "react";
import styled from "styled-components";
import EventComponent from "../components/event/EventComponent";
import Container from "./Container";
import {LoadComponent} from "../components/loader/LoaderComponent";
import {useDispatch, useSelector} from "react-redux";
import {getWeekEventsAction} from "../ContextProvider/Actions";

export default function Carousel({title}) {
   const dispatch = useDispatch();
   const {weekEvents, fetchingWeek} = useSelector((state) => state.events);
   const [stepSize, setStepSize] = useState(200);
   const [currentScroll, setCurrentScroll] = useState(0);
   const [lastIsVisible, setLastIsVisible] = useState(false);
   const CarouselRef = useRef(null);

   useEffect(() => {
      dispatch(getWeekEventsAction());
   }, []);

   useEffect(() => {
      CarouselRef.current.addEventListener("scroll", listenToScroll);
      return CarouselRef.current.removeEventListener("scroll", () => {});
   });

   const calculateChildSize = (size) => {
      setStepSize(size);
   };

   const listenToScroll = () => {
      const currentScrollSize = CarouselRef.current.scrollLeft;
      setCurrentScroll(currentScrollSize);
   };

   const toBackAction = () => {
      let newScroll = CarouselRef.current.scrollLeft - stepSize;
      CarouselRef.current.scrollTo({top: 0, left: newScroll, behavior: "smooth"});
   };

   const toNextAction = () => {
      let newScroll = CarouselRef.current.scrollLeft + stepSize;
      CarouselRef.current.scrollTo({top: 0, left: newScroll, behavior: "smooth"});
   };

   const checkLast = (ev) => {
      setLastIsVisible(ev);
   };

   return (
      <CarouselContent>
         {currentScroll > 0 && (
            <ButtonCarousel onClick={toBackAction} className="toBack">
               &#10094;
            </ButtonCarousel>
         )}
         <Container>
            <p className="title">{title}</p>
         </Container>
         <CarouselStyled ref={CarouselRef}>
            {React.Children.toArray(
               weekEvents.map((item, index) => {
                  let nIndex = Number(index);
                  return (
                     <EventComponent
                        isOnline={false}
                        data={item}
                        toSize={nIndex === 0}
                        calculateSize={calculateChildSize}
                        last={nIndex === weekEvents.length - 1}
                        checkLast={checkLast}
                     />
                  );
               }),
            )}
         </CarouselStyled>
         {weekEvents.length === 0 && !fetchingWeek && (
            <Container>
               <p style={{textAlign: "center"}}>No hay eventos dispoibles</p>
            </Container>
         )}
         {fetchingWeek && <LoadComponent isVisible={true} />}

         {!lastIsVisible && (
            <ButtonCarousel onClick={toNextAction} className="toNext">
               &#10095;
            </ButtonCarousel>
         )}
      </CarouselContent>
   );
}
Carousel.defaultProps = {
   title: "Tipo de eventos",
};

const CarouselContent = styled.div`
   position: relative;
   margin-top: 1.5em;

   & .title {
      margin-bottom: 0.5em;
      font-size: 1.8em;
      font-weight: 300;
      color: white;
   }
`;

const CarouselStyled = styled.div`
   display: flex;
   scroll-snap-type: x proximity;
   overflow-x: scroll;
   margin-left: 5%;

   & > article {
      max-width: 350px;
      min-width: 350px;
      height: 100%;
      scroll-snap-align: start;
      box-sizing: content-box;
   }

   ::-webkit-scrollbar {
      display: none;
   }

   @media screen and (max-width: 1000px) {
      & > article {
         max-width: 270px;
         min-width: 270px;
      }
   }
   @media screen and (max-width: 600px) {
      & > article {
         max-width: 250px;
         min-width: 250px;
      }
   }
`;

const ButtonCarousel = styled.button`
   background: rgba(10, 10, 10, 0.5);
   color: white;
   font-weight: 400;
   font-size: 2em;
   border: none;
   width: 1.8em;
   height: 1.8em;
   border-radius: 50%;
   position: absolute;
   top: calc(50% - 1.5em);
   z-index: 9;

   cursor: pointer;
   outline: none;

   &:hover {
      background: rgba(10, 10, 10, 0.8);
   }

   &.toBack {
      left: 0;
   }
   &.toNext {
      right: 0;
   }

   @media screen and (max-width: 600px) {
      font-weight: 300;
      font-size: 2em;
      width: 2em;
      height: 2em;
      top: calc(50% - 1em);
   }
`;
