import React, {useRef, useEffect, useState} from "react";
import styled from "styled-components";
import {ReactComponent as CalendarIcon} from "../../assets/icons/calendar.svg";
import {ReactComponent as ClockIcon} from "../../assets/icons/clock.svg";
import {Link} from "react-router-dom";
import AnimateImg from "../../containers/AnimateImg";
import {hostUrl} from "../../services/ApiUrl";

export default function EventComponent({data, calculateSize, toSize}) {
   const [isVisible] = useState(true);
   const ElementRef = useRef(null);

   useEffect(() => {
      if (toSize) {
         calculateSize(ElementRef.current.offsetWidth);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [toSize]);

   return (
      <EventStyled ref={ElementRef} className={isVisible ? "" : "ghost"}>
         <EventImg to={`/stream/${data.id}`} className={`${!!data.active ? "online" : ""} imgEvent`}>
            <AnimateImg src={`${hostUrl}${data.preview_img}`} alt={data.title} />
            {!!data.active && <div className="isOnlineBar">EN VIVO</div>}
         </EventImg>
         <EventInfoBox to={`/stream/${data.id}`}>
            <InfoDate>
               <div className="section">
                  <CalendarIcon fill="white" width="1em" height="1em" className="IconDate" />
                  <p className="infoDate">{data.date_event}</p>
               </div>
               <div className="section">
                  <ClockIcon fill="white" width="1em" height="1em" className="IconDate" />
                  <p className="infoDate">{data.time_start.substring(0, 5)} hrs.</p>
               </div>
            </InfoDate>
            <p className="titleEvent">{data.title}</p>
            <div className="divdesc">
               <p className="descriptionEvent">{data.description}</p>
            </div>
         </EventInfoBox>
      </EventStyled>
   );
}

EventComponent.defaultProps = {
   data: {
      idEvent: 1,
      eventName: "Nombre del evento",
      description: "Nombre del evento",
      img: require("../../assets/img/event_img.jpg"),
   },
};

const EventStyled = styled.article`
   width: 100%;
   padding: 0.5em;
   opacity: 1;
   transition: opacity 420ms linear;

   &.ghost {
      opacity: 0.5;
      transition: opacity 420ms linear;
   }

   &:first-child {
      padding-left: 0;
   }
`;

const EventInfoBox = styled(Link)`
   display: flex;
   flex-direction: column;
   text-decoration: none;

   & .titleEvent {
      font-size: 1.1em;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.5em;
      user-select: none;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
   & .divdesc {
      max-lines: 3;
   }
   & .descriptionEvent {
      font-size: 0.9em;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      user-select: none;
   }

   @media screen and (max-width: 900px) {
   }
   @media screen and (max-width: 600px) {
      & .titleEvent {
         font-size: 1.3em;
      }
      & .descriptionEvent {
         font-size: 0.9em;
      }
   }
`;

const InfoDate = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 0.5em 0;

   & .section {
      display: flex;

      & .IconDate {
         margin-right: 0.5em;
      }

      & p {
         font-size: 0.9em;
      }
   }

   @media screen and (max-width: 600px) {
      flex-wrap: wrap;
      margin-bottom: 0;

      & .section {
         margin-bottom: 0.5em;
      }
   }
`;

const EventImg = styled(Link)`
   display: block;
   width: 100%;

   & img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      filter: brightness(0.9);
      transition: filter 420ms ease;
   }

   &.online {
      position: relative;

      & .isOnlineBar {
         content: "EN VIVO";
         width: 100%;
         position: absolute;
         bottom: 0;
         padding: 0.5em 0;
         background: ${(props) => props.theme.primary};
         color: ${(props) => props.theme.background};
         text-align: center;
      }
   }

   &:hover {
      & img {
         filter: brightness(1);
         transition: filter 420ms ease;
      }
   }

   @media screen and (max-width: 1000px) {
      & img {
         height: 175px;
      }
   }
   @media screen and (max-width: 600px) {
      & img {
         height: 150px;
      }
   }
`;
