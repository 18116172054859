import React, {useState, useEffect} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Container from "../../../containers/Container";
import {SubtitleCover, TextCover} from "../about/sectors/AboutStyles";
import styled, {useTheme} from "styled-components";
import Input from "../../../components/auth/Input";
import {Button, ButtonLink} from "../../../styles/ButtonStyles";
import base64 from "base-64";
import {useHistory} from "react-router-dom";
import {userDataService, changePasswordService} from "../../../services/AuthServices";

export default function ChangePassword() {
   const [errors, setErrors] = useState("");
   const [decodeToken, setDecodeToken] = useState(null);
   const [isValid, setIsValid] = useState(false);
   const [successChange, setSuccess] = useState(false);
   const theme = useTheme();
   const urlParams = new URLSearchParams(window.location.search);
   const token = urlParams.get("token");
   const history = useHistory();

   useEffect(() => {
      validateSession();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const updatePassword = (e) => {
      e.preventDefault();
      let password = e.target.password.value;
      let sPassword = e.target.sPassword.value;

      if (password !== sPassword) return setErrors("Las contraseñas no coinciden");
      if (!token) return setErrors("Error al verificar tu identidad.");
      changePasswordService({password, token: decodeToken})
         .then((res) => res.json())
         .then((resp) => {
            const {status} = resp;
            if (status === "error") throw new Error();
            setSuccess(true);
         })
         .catch(() => {
            setErrors("Algo salió mal, intenta más tarde");
         });
   };

   const validateSession = () => {
      if (!token) return history.replace("/");
      const decoded = base64.decode(token);
      setDecodeToken(decoded);
      userDataService(decoded)
         .then((res) => res.json())
         .then((resp) => {
            const {status} = resp;
            if (status === "error") throw new Error();
            setIsValid(true);
         })
         .catch(() => {
            setIsValid(false);
         });
   };

   return (
      <AnimatedView>
         <Container>
            <ContentChange>
               <SubtitleCover>Actualizar contraseña</SubtitleCover>
               {!isValid ? (
                  <>
                     <SubtitleCover>Algo saló mal...</SubtitleCover>
                     <SubtitleCover>Intenta más tarde</SubtitleCover>
                  </>
               ) : successChange ? (
                  <>
                     <SubtitleCover>Contraseña actualizada</SubtitleCover>
                     <ButtonLink to="/?modal=login">Iniciar sesión</ButtonLink>
                  </>
               ) : (
                  <>
                     <form onSubmit={updatePassword}>
                        <Input
                           placeholder="Nueva contraseña"
                           name="password"
                           placeholderColor={theme.background}
                           activePlaceholder={theme.primary}
                           background={theme.primary}
                           color={theme.background}
                           type="password"
                        />
                        <Input
                           placeholder="Confirmar contraseña"
                           name="sPassword"
                           placeholderColor={theme.background}
                           activePlaceholder={theme.primary}
                           background={theme.primary}
                           color={theme.background}
                           type="password"
                        />
                        {errors && <TextCover color="red">{errors}</TextCover>}
                        <Button>Actualizar contreña</Button>
                     </form>
                  </>
               )}
            </ContentChange>
         </Container>
      </AnimatedView>
   );
}

const ContentChange = styled.div`
   width: 40%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 2em 0;
   flex-direction: column;

   & form {
      width: 70%;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & ${TextCover} {
         margin-bottom: 0;
      }

      & button {
         margin-top: 2em;
      }
   }
`;
