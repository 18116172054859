import React from "react";
import {CoverContent, TitleCover, SubtitleCover, TextCover} from "../../styles/HomeStyles";
import styled from "styled-components";
import {ButtonLink} from "../../styles/ButtonStyles";
import {hostUrl} from "../../services/ApiUrl";

export default function SlideCenter({data}) {
   return (
      <CustomCover background={hostUrl + data.preview_img}>
         {/* <SubtitleCover className="compress">{data.active ? "EN VIVO AHORA" : "PRÓXIMO EVENTO"}</SubtitleCover> */}
         {/*  <TitleCover color={theme.primary} className="compress">
            {data.title}
         </TitleCover> */}
         {/* <TextCover className="compress">
            {data.date_event.replace(/-/g, "/")} - {data.time_start.substring(0, 5)} HRS
         </TextCover> */}
         {/* <TextCover className="only-desk">{data.description}</TextCover> */}
         <ButtonLink to={`/stream/${data.id}`}>Ver ahora</ButtonLink>
      </CustomCover>
   );
}

SlideCenter.defaultProps = {
   data: {
      preview_img: "https://appfreeticket.com/static/media/about_background.28bdfda6.jpg",
      title: "ESTRENO STAY HOME COMEDY COLOMBIA",
      active: true,
      id: 2,
      time_start: "20:30",
      date_event: "2020-05-11",
      description:
         "Un especial que reuna lo más característico de la comedia sobre.... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean in tristique lacus.",
   },
};

const CustomCover = styled(CoverContent)`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   height: 100%;
   flex-direction: column;

   & .compress {
      letter-spacing: 0;
      font-weight: 600;
      transform: scaleY(1.2);
      text-transform: uppercase;
      /* margin-bottom: 0.5em; */
      margin-top: 0;
   }

   & ${SubtitleCover} {
      font-size: 1.7em;
      font-weight: 500;
      text-align: center;

      @media screen and (max-width: 600px) {
         // mobile
         font-size: 1.3em;
      }
   }

   & ${TitleCover} {
      margin-bottom: 0.3em;
      text-align: center;
   }

   & ${TextCover} {
      max-width: 50%;
      text-align: center;
      font-size: 0.9em;

      .compress {
         font-size: 1.1em;
      }
   }

   & ${ButtonLink} {
      font-weight: bold;
      padding-left: 2em;
      padding-right: 2em;
   }

   @media screen and (max-width: 600px) {
      justify-content: flex-end;
      padding: 1em 0.5em;

      & ${TextCover} {
         width: 100%;
         max-width: 100%;
      }

      & .only-desk {
         display: none;
      }
   }
`;
