import styled from "styled-components";
import Container from "../../../../containers/Container";

export const AboutSection = styled.section`
   width: 100%;
   height: 100%;

   padding: 2em 0;
   background: ${(props) => props.background || props.theme.background};
`;

export const TextAbout = styled.p`
   font-size: 1.3em;
   text-align: center;

   &.restricted {
      width: 80%;
      margin: 0 auto;
   }

   @media screen and (max-width: 600px) {
      font-size: 1.05em;

      &.restricted {
         width: 90%;
      }
   }
`;
export const MediaWe = styled.div`
   width: 90%;
   margin: 0 auto;
   margin-top: 3em;

   & .imgAbout {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
`;

export const OfferList = styled.div`
   width: 100%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   @media screen and (max-width: 600px) {
      flex-wrap: wrap;
   }
`;

export const OfferItem = styled.div`
   width: 20%;
   padding: 1em;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & .offerIcon{
      height: 7em;
      margin-bottom: 2em;
      object-fit: cover;
   }  

   & p {
      font-size: 1em;
      color: ${(props) => props.theme.background};
      text-align: center;
      margin-top: 1em:
   }

   @media screen and (max-width: 600px){
      width: 45%;
      padding: 0;
      margin: 1.5em 0;

      
      & .offerIcon{
         margin-bottom: 1em;
      } 

      
      & p {
      
         margin-top: 0:
      }

   }

`;

export const DownAppSection = styled.div`
   width: 100%;
   display: flex;
   justify-content: center;
   padding: 3em 0;

   @media screen and (max-width: 600px) {
      padding: 1.5em 0;
      flex-direction: column;
   }
`;

export const ColDown = styled.div`
   width: 45%;

   &.minCol {
      width: 35%;

      & .imgdown {
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   &.maxCol {
      width: 60%;
      padding: 0 1.5em;
   }

   @media screen and (max-width: 600px) {
      &.minCol,
      &.maxCol {
         width: 100%;
      }

      &.minCol {
         display: flex;
         & .imgdown {
            width: 50%;
            height: 50%;
            object-fit: cover;
            margin: 0 auto;
            display: block;
         }
      }

      &.maxCol {
         // margin-bottom: 2em;
         margin-top: 1em;
      }
   }
`;

export const LogoSection = styled.div`
   width: 25%;
   margin: 0 auto;
   margin-bottom: 1em;

   & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   &.mobile {
      display: none;
   }

   @media screen and (max-width: 600px) {
      width: 35%;
      margin-bottom: 1em;
      display: none;

      &.mobile {
         display: block;
      }
   }
`;

export const LinkCont = styled.div`
   margin-top: 2em;

   & .iconsL {
      margin-top: 1.5em;
      display: flex;
      justify-content: center;
   }

   @media screen and (max-width: 600px) {
      & .iconsL {
         justify-content: center;
         align-items: center;
         flex-direction: column;
      }
   }
`;

export const ExternalLink = styled.a`
   display: block;
   margin: 0 1em;

   & .imgLink {
      & img {
         width: auto;
         height: 60px;
         object-fit: cover;
      }
   }

   @media screen and (max-width: 600px) {
      margin-bottom: 1em;
      &:last-child {
         margin-bottom: 0;
      }

      & .imgLink {
         width: 180px;
         & img {
            width: 100%;
            height: auto;
            object-fit: cover;
         }
      }
   }
`;

// Cover

export const ContentCover = styled(Container)`
   // width: 100%;
   height: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   @media screen and (max-width: 600px) {
      // mobile
      padding-top: 2em;
      flex-direction: column;
      justify-content: center;
   }
`;

export const ColLeft = styled.div`
   width: 50%;

   &.imgAboutContent {
      display: flex;
      justify-content: center;
      align-items: center;
      & .imgAbout {
         width: 25%;
         display: block;
         margin: 0 auto;
      }
   }

   @media screen and (max-width: 900px) {
      width: 60%;

      &.imgAboutContent {
         width: 40%;
         & .imgAbout {
            width: 50%;
         }
      }
   }
   @media screen and (max-width: 600px) {
      width: 100%;
   }
`;

export const TitleCover = styled.h1`
   font-size: 2em;
   font-weight: 800;

   & span {
      color: ${(props) => props.theme.primary};
      font-weight: inherit;
   }

   @media screen and (max-width: 600px) {
      // mobile
      font-size: 1.5em;
      margin-bottom: 0.1em;
   }
`;
export const SubtitleCover = styled.h3`
   font-size: 2em;
   font-weight: 300;
   margin-bottom: 0.2em;

   @media screen and (max-width: 600px) {
      // mobile
      font-size: 1.5em;
      margin-bottom: 0.5em;
   }
`;

export const TextCover = styled.p`
   // width: 70%;
   margin: 0 auto;
   font-size: 0.9em;
   font-weight: 300;
   margin-top: 1.5em;
   margin-bottom: 2em;
   margin-left: 0;
   color: ${(props) => props.color || props.theme.text};
`;
