/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import UpdateForm from "./components/UpdateForm";
import {useSelector} from "react-redux";

export default function EditProfileView() {
   const {fetching, id} = useSelector((state) => state.auth);
   const [lister, setListener] = useState(false);

   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      if (lister && !fetching) history.replace(location.pathname);
   }, [lister, fetching]);

   if (!id) history.replace(location.pathname);
   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace(location.state ? location.state.back : "?modal=login");
            }}
            isLoading={fetching}
         >
            <ContentCard>
               <UpdateForm
                  listener={() => {
                     setListener(true);
                  }}
               />
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
