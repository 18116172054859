import React from "react";
import {ContentData, ContentButtons, ProfileCol, ColTitle, ColText} from "../../../../styles/ProfileStyles";
import {LoadComponent} from "../../../../components/loader/LoaderComponent";
import {Button, ButtonLink} from "../../../../styles/ButtonStyles";

export default function UserInfo({isLoadingData, currentUser, doLogout}) {
   return (
      <ContentData>
         {isLoadingData ? (
            <LoadComponent isVisible={true} />
         ) : (
            <>
               {React.Children.toArray(
                  currentUser.map((data) => {
                     return (
                        <ProfileCol className={data.className}>
                           <ColTitle>{data.name}</ColTitle>
                           <ColText>{data.value}</ColText>
                        </ProfileCol>
                     );
                  }),
               )}
               <ContentButtons>
                  <ButtonLink to="?modal=edit-profile">EDITAR PERFIL</ButtonLink>
                  <Button onClick={doLogout}>CERRAR SESIÓN</Button>
               </ContentButtons>
            </>
         )}
      </ContentData>
   );
}
