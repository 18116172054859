/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {useTransition, animated} from "react-spring";

const ToggleAnimate = ({childrenList, currentChild, className, propsToChild}) => {
   const transitions = useTransition(currentChild, null, {
      from: {opacity: 0, transform: "scale(0)", position: "absolute"},
      enter: {opacity: 1, transform: "scale(1)"},
      leave: {opacity: 0, transform: "scale(1.6)"},
   });
  

   return transitions.map(({item, props, key}) => (
      <animated.div key={key} style={props} className={className}>
         <SwitcChildrens current={currentChild} childrenList={childrenList} propsToChild={propsToChild} />
      </animated.div>
   ));
};

export default ToggleAnimate;

ToggleAnimate.defaultProps = {
   childrenList: [],
};

const SwitcChildrens = ({current, childrenList, propsToChild}) => {
   return (
      <>
         {React.Children.toArray(
            childrenList.map((itemModal) => {
               if (current === itemModal.trigger) return <itemModal.component isActive={true} propsToChild={propsToChild} />;
               return null;
            }),
         )}
      </>
   );
};
