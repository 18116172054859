import React from "react";
import styled from "styled-components";
import Container from "../../containers/Container";
import ContactSection from "./components/ContactSection";

export default function Footer() {
   return (
      <FooterStyled>
         <ContactSection />
         <Container className="footer-end">
            <p>Todos los derechos reservados. Freeticket&copy;</p>
         </Container>
      </FooterStyled>
   );
}

const FooterStyled = styled.footer`
   width: 100%;
   padding: 1em 0;
   background: ${(props) => props.theme.primary};

   & ${Container} {
      display: flex;
      justify-content: center;
      align-items: center;
   }

   & p {
      font-size: 1.1em;
      user-select: none;
      color: ${(props) => props.theme.background};
   }

   @media screen and (max-width: 600px) {
      & ${Container} {
         flex-direction: column;
      }

      & p {
         text-align: center;
      }
   }
`;
