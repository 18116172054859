/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";
import {StreamViewContent, TimeEndStyle, DescriptionData, CoverEvent} from "../../../styles/StreamStyles";
import {ButtonLink} from "../../../styles/ButtonStyles";
import AnimateImg from "../../../containers/AnimateImg";
import PlayerTheo from "./theo_player/TheoPlayerClass";
import "./theo_player/video.css";
import {useSelector, useDispatch} from "react-redux";
import {checkRemainingTime, passAssistanceAction} from "../../../ContextProvider/Actions";
import useScript from "../../../hooks/useScript";
import VideoLoader from "../../../components/loader/VideoLoader";
import {theoKey, hostUrl} from "../../../services/ApiUrl";

export default function VideoPlayer() {
   const [loaded] = useScript(`https://cdn.myth.theoplayer.com/${theoKey}/THEOplayer.js`);

   const dispatch = useDispatch();
   const history = useHistory();
   const {id} = useParams();
   const {streamEvent} = useSelector((state) => state.events);
   const {token, membership} = useSelector((state) => state.auth);
   const {streams} = useSelector((state) => state.stream);
   const {validCode, freecode} = streams[id] ?? {};

   useEffect(() => {
      console.log(streamEvent.streaming_link);
   }, [streamEvent]);

   useEffect(() => {
      let checkTime = "";
      if (validCode && freecode) {
         let send = {id_live_event: id, token, code: freecode};
         checkTime = setInterval(() => {
            dispatch(checkRemainingTime(send));
         }, 180000);
      }
      return () => {
         clearInterval(checkTime);
      };
   }, [validCode, freecode]);

   useEffect(() => {
      if (membership && !!streamEvent.active) {
         dispatch(passAssistanceAction("membership", id));
      }
   }, [membership]);

   useEffect(() => {
      if (!!streamEvent.active) {
         if (typeof membership === "boolean") {
            if (!membership) {
               if (freecode === undefined) {
                  return history.push("?modal=lobby");
               }
               if (freecode === "expiredCode") return history.push("?modal=pays");
            }
            // if (membership) return null;
         }
      }
   }, [membership, freecode]);

   if (!loaded) return <VideoLoader />;

   // active event
   if (membership || validCode) {
      // valid membership OR valid code and free time
      return (
         <StreamViewContent className="noHeight">
            <div style={{display: "inline-block", zoom: 1, width: "100%"}}>
               <PlayerTheo source={streamEvent.streaming_link} type={"application/x-mpegurl"} />
            </div>
         </StreamViewContent>
      );
   }

   if (!validCode && freecode) {
      // invalid membership OR invalid code and time expired
      return (
         <StreamViewContent>
            <TimeEndStyle>
               <DescriptionData>
                  Tus 15 min gratis han concluido; pero no te preocupes, puedes adquirir tu membresía para seguir disfrutando del evento y
                  de todos los que Freeticket tiene para ti.
               </DescriptionData>
               <ButtonLink to="?modal=pays">ADQUIRIR MEMBRESÍA</ButtonLink>
            </TimeEndStyle>
         </StreamViewContent>
      );
   }

   return <BannerEvent streamEvent={streamEvent} />;
}

const BannerEvent = ({streamEvent}) => (
   <CoverEvent>
      <AnimateImg src={hostUrl + streamEvent.preview_img} alt="img" className="imgCoverEvent" />
   </CoverEvent>
);
