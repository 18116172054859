import React from "react";
import {AppText, BigText} from "../styles/AuthStyles";

export default function PriceData({type}) {
   if (type === "card") {
      return (
         <AppText>
            <AppText>MEXICO: Pago mensual por 3 usd</AppText>
            {/* <AppText>COLOMBIA: Pago mensual por 11.250 COP</AppText> */}
         </AppText>
      );
   }
   return (
      <React.Fragment>
         <AppText>-------------------</AppText>
         <BigText>México</BigText>
         <AppText>Pago mensual por 66 Pesos</AppText>
         <React.Fragment>
            <AppText>FAMA STAND UP SAS DE CV</AppText>
            <AppText>BANCO: Banamex</AppText>
            <AppText>CUENTA CLABE: 002180701162687850</AppText>
         </React.Fragment>
         <AppText>-------------------</AppText>
         <BigText>Colombia</BigText>
         <AppText>Pago mensual por 11.250 COP</AppText>
         <React.Fragment>
            <AppText>Free Ticket SAS</AppText>
            <AppText>NIT 901.257.240-3</AppText>
            <AppText>BANCO: Davivienda</AppText>
            <AppText>CUENTA CORRIENTE:166169996948</AppText>
         </React.Fragment>
         <AppText>-------------------</AppText>
      </React.Fragment>
   );
}
