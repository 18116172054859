import {
   GET_ALL_EVENTS,
   GET_ALL_EVENTS_SUCCESS,
   GET_ALL_EVENTS_ERROR,
   GET_EVENTS_WEEK,
   GET_EVENTS_WEEK_SUCCESS,
   GET_EVENTS_WEEK_ERROR,
   GET_TODAY_EVENTS,
   GET_TODAY_EVENTS_SUCCESS,
   GET_TODAY_EVENTS_ERROR,
   GET_DATA_EVENT,
   GET_DATA_EVENT_SUCCESS,
   GET_DATA_EVENT_ERROR,
} from "../Types";

const initialState = {
   weekEvents: [],
   todayEvents: [],
   allEvents: [],
   streamEvent: [],
};

function EventsReducer(state = initialState, action) {
   switch (action.type) {
      // list
      case GET_ALL_EVENTS:
         return {...state, fetchingAll: true};
      case GET_ALL_EVENTS_SUCCESS:
         return {...state, fetchingAll: false, ...action.payload};
      case GET_ALL_EVENTS_ERROR:
         return {...state, fetchingAll: false, error: action.payload};
      // week
      case GET_EVENTS_WEEK:
         return {...state, fetchingWeek: true};
      case GET_EVENTS_WEEK_SUCCESS:
         return {...state, fetchingWeek: false, ...action.payload};
      case GET_EVENTS_WEEK_ERROR:
         return {...state, fetchingWeek: false, error: action.payload};
      // today
      case GET_TODAY_EVENTS:
         return {...state, fetchingToday: true};
      case GET_TODAY_EVENTS_SUCCESS:
         return {...state, fetchingToday: false, ...action.payload};
      case GET_TODAY_EVENTS_ERROR:
         return {...state, fetchingToday: false, error: action.payload};
      // event
      case GET_DATA_EVENT:
         return {...state, fetching: true};
      case GET_DATA_EVENT_SUCCESS:
         return {...state, fetching: false, streamEvent: {...action.payload}};
      case GET_DATA_EVENT_ERROR:
         return {...state, fetching: false, error: action.payload};
      default:
         return {...state};
   }
}

export default EventsReducer;
