import React from "react";
import styled from "styled-components";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {useHistory, useLocation} from "react-router-dom";

const View = styled.div``;

const InfoTitle = styled.p`
   font-size: 1.5em;
   text-align: center;
   color: ${(props) => props.theme.background};
   margin-bottom: 1em;
`;
const InfoParagraph = styled.p`
   font-size: 1em;
   color: ${(props) => props.theme.background};
`;

export default function TermsAndConditions() {
   const history = useHistory();
   const location = useLocation();

   return (
      <ModalContainer>
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
         >
            <View>
               <InfoTitle>TÉRMINOS Y CONDICIONES FREETICKET APP</InfoTitle>
               <InfoParagraph>
                  Estos términos y condiciones de uso (en adelante, los "Términos") regulan el acceso y uso de la aplicación móvil “Free
                  Ticket” (en adelante, la "APP").
                  <br />
                  <br />
                  <br />
                  1. EL USUARIO manifiesta haber leído y entendido en su totalidad los Términos, por lo cual acepta irrevocablemente a
                  cumplir lo establecido en estos, así como con las leyes y reglamentos que sean aplicables.
                  <br />
                  <br />
                  2. EL USUARIO al suscribirse a la APP está aceptando adherirse plenamente a estos Términos, al igual que a todas sus
                  modificaciones y/o adiciones. En el evento en que la persona interesada en realizar la suscripción no desee aceptar o se
                  encuentre en desacuerdo con estos Términos, sus modificaciones y/o adiciones, no podrá realizar ni hacer efectiva la
                  suscripción a la APP, quedando impedido de participar en la totalidad de las campañas publicadas en la aplicación,
                  entendiéndose por Campaña el evento, servicio, actividad y/o producto del cual FREETICKET S.A.S. está regalando tiquetes
                  (en adelante “las Campañas).
                  <br />
                  <br />
                  3. FREE TICKET S.A.S., es una persona jurídica cuyo objeto social, debidamente constituida, para ejercer todas las
                  facultades legales, para ejercer las actividades descritas en los siguientes términos.
                  <br />
                  <br />
                  4. EL USUARIO es la persona previamente registrada en el APP, quien acepta haber suministrado toda la información real
                  personal requerida, y es él únicamente responsable por la información que no sea real allí registrada. Quien suministre
                  información o use su tiquete para falsificaciones o adulteraciones será responsable en los términos del código penal
                  aplicable.
                  <br />
                  <br />
                  5. EL USUARIO se compromete a utilizar la APP de conformidad con estos Términos y de acuerdo a la legislación local
                  aplicable, según el país que corresponda (Colombia o México), teniendo en cuenta el lugar donde se desarrolle la Campaña
                  por parte de FREETICKET S.A.S.
                  <br />
                  <br />
                  6. EL USUARIO se abstendrá de utilizar la APP con fines o efectos ilícitos, lesivos de los derechos e intereses de
                  terceros, o de realizar actos que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir el normal
                  funcionamiento de la APP.
                  <br />
                  <br />
                  7. PROPIEDAD INTELECTUAL DE LA APP: FREETICKET S.A.S. ha dispuesto en el Sitio ciertos contenidos tales como mensajes,
                  diseños, códigos fuente, códigos objeto, animaciones, gráficos, archivos de sonido y/o imagen, fotografías, grabaciones, y
                  software (los "Contenidos"), siendo los derechos de autor sobre los Contenidos de propiedad de FREETICKET S.A.S. o del
                  creador original de los mismos, de quien FREE TICKET S.A.S. ha recibido autorización para su uso, y están plenamente
                  protegidos por las normas nacionales e internacionales de derechos de autor y propiedad intelectual. En consecuencia,
                  salvo lo expresamente señalado en los Términos, EL USUARIO deberá abstenerse de copiar, divulgar o reproducir de cualquier
                  forma y por cualquier medio los Contenidos y, en general, cualquier clase de material accesible a través de la APP, salvo
                  aquellos casos en que FREE TICKET S.A.S. haya autorizado expresamente su copia o reproducción. En el caso que cualquier
                  Usuario o un tercero considere que cualquiera de los Contenidos ha sido introducido en la APP con violación de sus
                  derechos de propiedad intelectual, dicho Usuario o tercero deberá enviar una notificación al siguiente correo
                  appfreeticke@gmail.com informando sus datos de identificación, indicación de los derechos vulnerados por FREETICKET S.A.S.
                  y una declaración expresa, donde garantice que la información proporcionada en la notificación es veraz y exacta.
                  <br />
                  <br />
                  8. EL USUARIO deberá estar suscrito a la APP para conocer el detalle puntual de cada una de las actividades objeto de las
                  Campañas. En caso de que se encuentre interesado en participar en alguna de las Campañas deberá inscribirse en cada una de
                  estas, y FREETICKET S.A.S. le comunicara al correo registrado en la suscripción las fechas en las que la APP va a soltar
                  tiquetes, debiendo EL USUARIO estar pendiente para participar por los tiquetes, quedando claro que la sola suscripción a
                  la APP no asegura a EL USUARIO la obtención de los tiquetes de las Campañas.
                  <br />
                  <br />
                  9. FREETICKET S.A.S. no concede ninguna licencia o autorización de uso de ninguna clase sobre sus derechos de propiedad
                  industrial e intelectual o sobre cualquier otra propiedad o derecho relacionado con la APP o sus Contenidos.
                  <br />
                  <br />
                  10. Las marcas comerciales, las marcas de servicio y los logos usados y/o desplegados en la APP son marcas comerciales de
                  propiedad de FREETICKET S.A.S., nada en esta APP podrá ser interpretado como concesión de licencias o derechos de uso
                  sobre cualesquiera Marcas Comerciales desplegadas en la APP.
                  <br />
                  <br />
                  11. FREETICKET S.A.S. tendrá la libertad de lanzar cada una de sus Campañas en los tiempos que considere pertinentes,
                  siendo claro que en ningún momento estará obligado con los usuarios a alguna periodicidad específica para la realización
                  de las campañas.
                  <br />
                  <br />
                  12. Para cada Campaña el número de tiquetes disponibles no es ilimitado, sujetándose estos a la cantidad de tiquetes que
                  para tal fin haya adquirido FREETICKET S.A.S., siendo claro para EL USUARIO que el número de ganadores por campaña está
                  sujeto al número de tiquetes disponibles.
                  <br />
                  <br />
                  13. EL USUARIO declara conocer que la realización de eventos, prestación de servicios, entrega de productos, o cualquier
                  otra actividad objeto de la Campaña realizada por FREETICKET S.A.S., de la que EL USUARIO quede ganador por intermedio de
                  la APP no depende de FREE TICKET S.A.S., quien no se responsabiliza por horarios, calidad, condiciones de seguridad,
                  ubicaciones, realización, organización, cancelación de las actividades, entre otros inconvenientes que puedan presentar
                  las actividades objeto de los tiquetes de la Campaña; Como consecuencia de esto el único y/o exclusivamente responsable de
                  estas situaciones es el TERCERO que aparece como emisor de los tiquetes, exonerando EL USUARIO a FREETICKET de cualquier
                  responsabilidad generada por el incumplimiento de las obligaciones del TERCERO, debiendo EL USUARIO iniciar a nombre y
                  cuenta propia el correspondiente proceso de reclamación frente a ese TERCERO.
                  <br />
                  <br />
                  14. Todo Usuario que pretenda acceder, suscribirse, utilizar y/o participar en las campañas publicadas en la APP, deberá
                  cumplir en todo con estos Términos, la Política de Privacidad y Protección de Datos Personales de FREETICKET S.A.S. y por
                  lo tanto deberá otorgar a FREETICKET S.A.S la autorización para el manejo de sus datos personales.
                  <br />
                  <br />
                  15. Las suscripciones realizadas por EL USUARIO en el APP están sujetas a la verificación, y aceptación de la tarjeta
                  débito o crédito por parte del BANCO, o entidad financiera, así como a la verificación de los datos que EL USUARIO ingresa
                  al momento de realizar la suscripción. EL USUARIO reconoce y acepta que FRITICKET S.A.S. no controla de ninguna forma las
                  páginas en las que se realizan las transacciones a través de tarjetas de débito o transferencia bancaría en línea. En
                  consecuencia, en ningún caso FRITICKET S.A.S. será responsable por el manejo de la información que EL USUARIO deba
                  suministrar a las entidades bancarias con ocasión de la realización de dichas transacciones. Lo anterior, habida cuenta
                  que el manejo de la transmisión y archivo de la información mencionada es responsabilidad exclusiva de las entidades
                  bancarias y financieras a las que se encuentra afiliado el titular de la tarjeta.
                  <br />
                  <br />
                  16. EL USUARIO acepta y conoce que el valor mensual de la suscripción será descontado automáticamente por FREETICKET de la
                  tarjeta débito o crédito ingresada por EL USUARIO para dicha finalidad, sin embargo, en cualquier momento EL USUARIO
                  tendrá la libertad de modificar el método de pago y/o cancelar la suscripción a la APP.
                  <br />
                  <br />
                  17. EL USUARIO acepta que la información personal ingresada al sistema FREE TICKET es de su responsabilidad y por lo tanto
                  garantiza su veracidad y exactitud. Por lo anterior en caso de inconsistencias en dicha información FREETICKET se podrá
                  abstener de entregar los tiquetes en caso de quedar EL USUARIO ganador en alguna de las campañas de la APP.
                  <br />
                  <br />
                  18. FREETICKET, no está obligado a hacer devoluciones de dinero por errores de EL USUARIO al ingresar la información, o
                  por no poder utilizar los tiquetes de las campañas de las que salió ganador, o en general causas ajenas a
                  responsabilidades propias o de sus agentes.
                  <br />
                  <br />
                  19. FREETICKET S.A.S. se reserva el derecho a modificar, por su mera liberalidad el valor de la suscripción a la APP, para
                  esto solo requerirá una publicación en la APP informando el nuevo valor. En ningún caso, FREETICKET S.A.S. será
                  responsable ante terceros por dicha situación.
                  <br />
                  <br />
                  20. FREETICKET S.A.S. no garantiza la disponibilidad, continuidad o buen funcionamiento de la APP. En consecuencia, en
                  cualquier tiempo y sin previo aviso, FREETICKET S.A.S., a su arbitrio, podrá bloquear, interrumpir o restringir el acceso
                  a la APP sin que por ello se derive responsabilidad alguna para FREETICKET S.A.S.
                  <br />
                  <br />
                  21. FREETICKET, no está obligado a reponer tiquetes perdidos, deteriorados, rotos o robados, estos son de entera
                  responsabilidad de EL USUARIO. Por lo tanto, en ningún caso aplicará el concepto de reembolso del valor de la suscripción
                  a EL USUARIO por parte de FREETICKET.
                  <br />
                  <br />
                  22. RESPONSABILIDAD: EL USUARIO será responsable por tomar medidas adecuadas y actuar diligentemente al momento de acceder
                  a la APP. Por lo tanto, FREETICKET S.A.S. no controla ni garantiza que los Contenidos se encuentren libres de errores,
                  virus u otros elementos de similar naturaleza que puedan producir alteraciones en el sistema informático de EL USUARIO o
                  en los documentos electrónicos y ficheros almacenados en el mismo. Derivado de esto es claro para EL USUARIO que
                  FREETICKET S.A.S no será responsable por los daños y/o perjuicios que pueda sufrir EL USUARIO como resultado de errores,
                  problemas de compatibilidad, virus o cualesquiera otros elementos de similar naturaleza que afecten la funcionalidad del
                  APP. Sin perjuicio de lo señalado en estos Términos, la responsabilidad de FREETICKER S.A.S. en ningún caso excederá el
                  valor que EL USUARIO reclamante hubiere pagado por concepto de la suscripción a la APP.
                  <br />
                  <br />
                  23. EL USUARIO acepta que la información registrada en la APP, así como de las transacciones efectuadas son de propiedad
                  de FREE TICKET; quien está autorizado para dar usos comerciales sin afectar en ningún caso la intimidad y seguridad de los
                  usuarios.
                  <br />
                  <br />
                  24. EL USUARIO acepta que los tiquetes deberán ser recogidos personalmente en las oficinas de FREETICKET S.A.S., ubicadas
                  en Calle 98 # 10-32 edificio pixel 98, oficina 401 estos serán entregadas a EL USUARIO suscrito, quien deberá exhibir la
                  tarjeta de crédito o débito con la que se encuentra suscrito, su documento de identidad y el código del tiquete que le
                  suministre la APP. No se aceptan poderes, avisos de pérdida de documentos, denuncios, noticias criminales o autorizaciones
                  a terceras personas, FREE TICKET no se hace responsable por ningún tipo de reembolso de dinero por tiquetes no reclamados.
                  <br />
                  <br />
                  25. FREETICKET S.A.S. se reserva el derecho retirar o negar el acceso a la APP en cualquier momento y sin necesidad de
                  preaviso, a aquellos Usuarios que incumplan estos Términos o cualesquiera otras disposiciones que resulten de aplicación.
                  <br />
                  <br />
                  26. EL USUARIO reconoce y acepta que el acceso y utilización de la APP se realiza bajo su propia cuenta, riesgo y
                  responsabilidad.
                  <br />
                  <br />
                  27. Las suscripciones efectuadas en la APP se entienden efectuadas en los términos de la legislación local aplicable, para
                  Colombia en particular la ley 527 de 1999, para México la ley de firma electrónica avanzada; razón por la que todas las
                  transacciones, consultas y compras se reputan firmadas electrónicamente por el usuario, quien se obliga en los términos
                  legales en el negocio jurídico realizado.
                  <br />
                  <br />
                  28. EL USUARIO reconoce y acepta expresamente que FREETICKET S.A.S. no es responsable por las conductas, ofensivas,
                  ilegales y/o delictivas de sus Usuarios.
                  <br />
                  <br />
                  29. Cualquier diferencia surgida entre las partes será dirimida en derecho aplicando la legislación local aplicable, ya
                  sea la colombiana y/o mexicana, dependiendo del país donde se esté desarrollando la Campaña, EL USUARIO se obliga a hacer
                  cualquier reclamación directamente a FREETICKET S.A.S., y usar todos los métodos alternativos de solución de conflictos.
                  <br />
                  <br />
                  30. FREETICKET S.A.S. garantiza que los contenidos y campañas de la APP son apropiados y van acorde únicamente con la
                  legislación colombiana y mexicana.
                  <br />
                  <br />
                  31. De conformidad con el Estatuto de la Ley del Consumidor Colombiana, el Usuario podrá en casos de ventas no
                  presenciales ejercer el derecho de retracto dentro de los términos y condiciones de la ley, para los USUARIOS qua
                  participen en campañas que se desarrollen en Colombia.
                  <br />
                  <br />
                  32. Estos Términos, y la Política de Privacidad, forman un documento único que debe interpretarse y cumplirse en su
                  conjunto.
                  <br />
                  <br />
                  33. Ante cualquier consulta, queja, reclamo y/o sugerencias, EL USUARIO y/o cualquier tercero interesado podrá comunicarse
                  a los siguientes correos electrónicos y teléfonos:
                  <br />
                  <br />
                  Colombia: directorcampco@appfreeticket.com <br />
                  Mexico: directorcampmx@appfreeticket.com
                  <br />
               </InfoParagraph>
            </View>
         </CardModalAuth>
      </ModalContainer>
   );
}
