import React, {useState} from "react";
import {listFutureService, listTodayService, listAllEventsService} from "../services/LiveServices";
import {sortByDate} from "../helpers/Sorts";

export const EventsContext = React.createContext();

export default function EventsProvider({children}) {
   const [futureEventList, setFutureEvents] = useState({isLoading: true, data: []});
   const [todayEventsList, setTodayEvents] = useState({isLoading: true, data: []});
   const [availableList, setAvailable] = useState({isLoading: true, data: []});

   const getFutureEvents = (force = false) => {
      if (futureEventList.data.length > 0 && !force) return false;
      listFutureService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;

            if (status === "error") throw new Error();
            let nData = sortByDate(data, "date_event");
            nData = nData.filter((item) => item.visibility);
            setFutureEvents({isLoading: false, data: nData});
         })
         .catch(() => {
            setFutureEvents({isLoading: false, data: []});
         });
   };
   const getTodayEvents = (force = false) => {
      if (todayEventsList.data.length > 0 && !force) return false;
      listTodayService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;

            if (status === "error") throw new Error();
            let nData = sortByDate(data, "date_event");
            nData = nData.filter((item) => item.visibility);
            setTodayEvents({isLoading: false, data: nData});
         })
         .catch(() => {
            setTodayEvents({isLoading: false, data: []});
         });
   };

   const getAllEvents = () => {
      listAllEventsService()
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            let allEvents = data.filter((event) => event.visibility && event.active);
            setAvailable({isLoading: false, data: [...allEvents]});
         })
         .catch((e) => {
            console.log(e);
         });
   };

   return (
      <EventsContext.Provider value={{futureEventList, getFutureEvents, todayEventsList, getTodayEvents, getAllEvents, availableList}}>
         {children}
      </EventsContext.Provider>
   );
}
