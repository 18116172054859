import {createGlobalStyle} from "styled-components";
import Roboto from "../assets/fonts/Roboto-Regular.ttf";
import RobotoThin from "../assets/fonts/Roboto-Thin.ttf";
import RobotoSemibold from "../assets/fonts/Roboto-Medium.ttf";
import RobotoBold from "../assets/fonts/Roboto-Black.ttf";

const GlobalStyle = createGlobalStyle`
   @font-face {
      font-family: Roboto;
      font-weight: 200;
      src: url(${RobotoThin});
   }
   @font-face {
      font-family: Roboto;
      font-weight: 400;
      src: url(${RobotoSemibold});
   }
   @font-face {
      font-family: Roboto;
      font-weight: 600;
      src: url(${RobotoBold});
   }
   @font-face {
      font-family: Roboto;
      font-weight: 300;
      src: url(${Roboto});
   }

  


   body {
      font-family: Roboto;
      margin: 0;
      background-color: ${(props) => props.theme.background};
   }

   div, section, button, select, aside, article{
      box-sizing: border-box;
   }

   p, input, textarea, select, span, pre, button, a, li, table, h1, h2, h3, h4, h5, h6, p, blockquote, b {
      font-family: Roboto;
      font-weight: 300;
      letter-spacing: 1px;
      color: ${(props) => props.theme.text};
   }

   p, h1, h2, h3, h4, h5, h6{
      margin: 0;
   }
   b {
      font-weight: 400;
   }

   .animate-img {
      img {
         margin: 0 auto;
         width: 100%;
      }
   }


   ::-moz-selection
      color: white;
      background: #557fa3;
    }
    
    ::selection {
      color: white;
      background: #557fa3;
    }

`;

export default GlobalStyle;
