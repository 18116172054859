import React from "react";
import styled from "styled-components";
import {ContentNav} from "../header/Nav";
import Container from "../../containers/Container";

export default function Cover({background, children}) {
   return (
      <>
         <CoverStyled>
            <Container>
               <div className="navHome">
                  <ContentNav />
               </div>
            </Container>
            <ChildView back={background}>{children}</ChildView>
         </CoverStyled>
      </>
   );
}

Cover.defaultProps = {
   background: require("../../assets/img/inicio_banner.jpg"),
   children: <></>,
};

const CoverStyled = styled.section`
   width: 100%;
   height: 100vh;
   margin: 0;
   position: relative;

   display: flex;
   /* flex-direction: column; */
   justify-content: flex-start;
   align-items: flex-start;

   & .navHome {
      padding-top: 1em;
      position: relative;
      z-index: 100;
   }

   @media screen and (max-width: 900px) {
      // tablet
      height: 80vh;
   }

   @media screen and (max-width: 600px) {
      // mobile
      height: 95vh;
   }

   @media screen and (max-width: 400px) {
      // mobile min
   }

   @media screen and (min-width: 2000px) {
      // max sizez
      height: 95vh;
   }
`;

const ChildView = styled.div`
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;

   background-image: url(${(props) => props.back});
   background-size: cover;
   background-position: center;

   & .react-multi-carousel-list {
      height: 100%;

      & ul {
         height: 100%;

         & li {
            height: 100%;
         }
      }
   }

   & .carousel-container {
      width: 100%;
      height: 100%;
   }
`;
