import React from "react";
import styled from "styled-components";

import "./loader.css";
import {useTransition, animated} from "react-spring";

const LoaderView = styled(animated.div)`
   width: 100%;
   height: 100%;
   background-color: rgba(10, 10, 10, 0.99);
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 10000000;

   & p {
      color: white;
      text-align: center;
   }
`;

export function AppLoader({isVisible}) {
   const transitions = useTransition(isVisible, null, {
      from: {position: "fixed", opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
   });
   return transitions.map(
      ({item, key, props}) =>
         item && (
            <LoaderView key={key} style={props}>
               <div className="preloader">
                  <div className="loader__figure"></div>
               </div>
            </LoaderView>
         ),
   );
}

AppLoader.defaultProps = {
   isVisible: false,
};
