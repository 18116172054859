// auth
const INIT_APP = "INIT_APP";
const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_ERROR = "LOGIN ERROR";
const LOG_OUT = "LOG_OUT";

// user
const GET_USERDATA = "GET_USERDATA";
const GET_USERDATA_SUCCESS = "GET_USERDATA_SUCCESS";
const GET_USERDATA_ERROR = "GET_USERDATA_ERROR";
const GET_MEMBERSHIP = "GET_MEMBERSHIP";
const GET_MEMBERSHIP_SUCCESS = "GET_MEMBERSHIP_SUCCESS";
const GET_MEMBERSHIP_ERROR = "GET_MEMBERSHIP_ERROR";
const RENEW_TOKEN = "RENEW_TOKEN";

const REGISTER_USER = "REGISTER_USER";
const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";

const UPDATE_PROFILE = "UPDATE_PROFILE";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

const PAY_MEMBERSHIP = "PAY_MEMBERSHIP";
const PAY_MEMBERSHIP_SUCCESS = "PAY_MEMBERSHIP_SUCCESS";
const PAY_MEMBERSHIP_ERROR = "PAY_MEMBERSHIP_ERROR";

// events
const GET_ALL_EVENTS = "GET_ALL_EVENTS";
const GET_ALL_EVENTS_SUCCESS = "GET_ALL_EVENTS_SUCCESS";
const GET_ALL_EVENTS_ERROR = "GET_ALL_EVENTS_ERROR";
const GET_TODAY_EVENTS = "GET_TODAY_EVENTS";
const GET_TODAY_EVENTS_SUCCESS = "GET_TODAY_EVENTS_SUCCESS";
const GET_TODAY_EVENTS_ERROR = "GET_TODAY_EVENTS_ERROR";
const GET_EVENTS_WEEK = "GET_EVENTS_WEEK";
const GET_EVENTS_WEEK_SUCCESS = "GET_EVENTS_WEEK_SUCCESS";
const GET_EVENTS_WEEK_ERROR = "GET_EVENTS_WEEK_ERROR";

// stream
const GET_DATA_EVENT = "GET_DATA_EVENT";
const GET_DATA_EVENT_SUCCESS = "GET_DATA_EVENT_SUCCESS";
const GET_DATA_EVENT_ERROR = "GET_DATA_EVENT_ERROR";
const INVALIDATE_CODE = "INVALIDATE_CODE";

// codes
const CHANGE_CODE = "CHANGE_CODE";
const CHANGE_CODE_SUCCESS = "CHANGE_CODE_SUCCESS";
const CHANGE_CODE_ERROR = "CHANGE_CODE_ERROR";
const GET_REMAING_TIME = "GET_REMAING_TIME";
const GET_REMAING_TIME_SUCCESS = "GET_REMAING_TIME";
const GET_REMAING_TIME_ERROR = "GET_REMAING_TIME_ERROR";
const PASS_ASSISTENCE = "PASS_ASSISTENCE";
const PASS_ASSISTENCE_ERROR = "PASS_ASSISTENCE_ERROR";

//
const NETWORK_ERROR = "NETWORK_ERROR";

export {
   // auth
   INIT_APP,
   LOGIN,
   LOGIN_SUCCESS,
   LOGIN_ERROR,
   LOG_OUT,
   // events LIST
   GET_ALL_EVENTS,
   GET_ALL_EVENTS_SUCCESS,
   GET_ALL_EVENTS_ERROR,
   //  ----- TODAY EVENTS----
   GET_TODAY_EVENTS,
   GET_TODAY_EVENTS_SUCCESS,
   GET_TODAY_EVENTS_ERROR,
   //  ----- EVENTS OF THE WEEK -----
   GET_EVENTS_WEEK,
   GET_EVENTS_WEEK_SUCCESS,
   GET_EVENTS_WEEK_ERROR,
   // stream
   GET_DATA_EVENT,
   GET_DATA_EVENT_SUCCESS,
   GET_DATA_EVENT_ERROR,
   // codes
   CHANGE_CODE,
   CHANGE_CODE_SUCCESS,
   CHANGE_CODE_ERROR,
   RENEW_TOKEN,
   GET_USERDATA,
   GET_USERDATA_SUCCESS,
   GET_USERDATA_ERROR,
   GET_MEMBERSHIP,
   GET_MEMBERSHIP_SUCCESS,
   GET_MEMBERSHIP_ERROR,
   NETWORK_ERROR,
   UPDATE_PROFILE,
   UPDATE_PROFILE_SUCCESS,
   UPDATE_PROFILE_ERROR,
   REGISTER_USER,
   REGISTER_USER_SUCCESS,
   REGISTER_USER_ERROR,
   GET_REMAING_TIME,
   GET_REMAING_TIME_SUCCESS,
   GET_REMAING_TIME_ERROR,
   PASS_ASSISTENCE,
   PASS_ASSISTENCE_ERROR,
   INVALIDATE_CODE,
   PAY_MEMBERSHIP,
   PAY_MEMBERSHIP_SUCCESS,
   PAY_MEMBERSHIP_ERROR,
};
