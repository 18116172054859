import Axios from "axios";
import {apiUrl} from "../../services/ApiUrl";
import {
   GET_TODAY_EVENTS,
   GET_TODAY_EVENTS_SUCCESS,
   GET_TODAY_EVENTS_ERROR,
   NETWORK_ERROR,
   GET_EVENTS_WEEK,
   GET_EVENTS_WEEK_SUCCESS,
   GET_EVENTS_WEEK_ERROR,
   GET_DATA_EVENT,
   GET_DATA_EVENT_SUCCESS,
   GET_DATA_EVENT_ERROR,
} from "../Types";

const URL = `${apiUrl}live_event/`;

export const getTodayEventsAction = (force = false) => (dispatch, getState) => {
   const {todayEvents} = getState().events;
   if (todayEvents.length > 0 && !force) return false;
   dispatch({
      type: GET_TODAY_EVENTS,
   });
   Axios.get(`${URL}list_today`)
      .then((resp) => {
         dispatch({
            type: GET_TODAY_EVENTS_SUCCESS,
            payload: {todayEvents: resp.data.data},
         });
      })
      .catch(() => {
         dispatch({
            type: GET_TODAY_EVENTS_ERROR,
            payload: NETWORK_ERROR,
         });
      });
};

export const getWeekEventsAction = (force = false) => (dispatch, getState) => {
   const {weekEvents} = getState().events;
   if (weekEvents.length > 0 && !force) return false;
   dispatch({
      type: GET_EVENTS_WEEK,
   });
   Axios.get(`${URL}list_future`)
      .then((resp) => {
         dispatch({
            type: GET_EVENTS_WEEK_SUCCESS,
            payload: {weekEvents: resp.data.data},
         });
      })
      .catch(() => {
         dispatch({
            type: GET_EVENTS_WEEK_ERROR,
            payload: NETWORK_ERROR,
         });
      });
};
export const getEventDataAction = (live_event_id) => (dispatch, getState) => {
   dispatch({
      type: GET_DATA_EVENT,
   });

   // search event in local data
   const {todayEvents, weekEvents} = getState().events;
   let event =
      todayEvents.find((event) => event.id === Number(live_event_id)) ?? weekEvents.find((event) => event.id === Number(live_event_id));

   if (event) {
      return dispatch({
         type: GET_DATA_EVENT_SUCCESS,
         payload: event,
      });
   }

   // API event search
   if (!live_event_id) {
      dispatch({
         type: GET_DATA_EVENT_ERROR,
         payload: "Invalid id",
      });
   }
   return Axios.post(`${URL}info`, {live_event_id: live_event_id})
      .then((resp) => {
         dispatch({
            type: GET_DATA_EVENT_SUCCESS,
            payload: resp.data.data,
         });
      })
      .catch((e) => {
         console.log(e);
         dispatch({
            type: GET_DATA_EVENT_ERROR,
            payload: NETWORK_ERROR,
         });
      });
};
