import React, {useState} from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText, ToggleForm} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import PayWithEpayCo from "./EpayCo/PayWithEpayCo";
import PayWithStripe from "./stripe/PayWithStripe";
import ToggleAnimate from "../../../containers/ToggleAnimate";
import {useSelector} from "react-redux";
import PriceData from "../../../components/PriceData";

export default function PayWhitCardView() {
   const {fetchingPay} = useSelector((state) => state.auth);
   const [method] = useState(2);
   const location = useLocation();
   const history = useHistory();

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace("?modal=pays");
            }}
            isLoading={fetchingPay}
         >
            <ContentCard>
               <BigText>Adquiere tu membresía</BigText>
               <AppText>Llena los siguientes campos para realizar el pago de tu membresía</AppText>
               <PriceData type="card" />

               <ToggleForm>
                  <ToggleAnimate currentChild={method} childrenList={childrenListForm} className="animateForm" />
               </ToggleForm>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}

const childrenListForm = [
   {
      trigger: 1,
      component: PayWithEpayCo,
   },
   {
      trigger: 2,
      component: PayWithStripe,
   },
];

/* <Select 
      placeholder={{value: "0", name: "Servicio de pago"}}
      options={[
         {name: "ePayco", value: 1},
                     {name: "Stripe", value: 2},
      ]}
      change={(e) => setMethod(Number(e.target.value))}
      name="country"
      defaultValue={1}
   />
*/
