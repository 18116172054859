/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useLocation, useHistory} from "react-router-dom";
import ModalContainer from "../../../containers/ModalContainer";
import {AppText, ContentCard, BigText, AuthButtonLink, ContentButtons} from "../../../styles/AuthStyles";
import CardModalAuth from "../../../containers/CardModalAuth";
import {useSelector} from "react-redux";

// Modal para pedir pagar membresía o ingresar código de regalo
export default function LobbyView() {
   const {membership, first_name} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      if (membership) {
         history.replace(location.pathname);
      }
   }, [membership]);

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
         >
            <ContentCard>
               <BigText>¡Hola {first_name}!</BigText>

               <AppText>
                  Adquiere tu membresía para disfrutar lo mejor de Freeticket en streaming o introduce tu código de 15 minutos gratis
               </AppText>

               <ContentButtons>
                  <AuthButtonLink to={`?modal=pays&backModal=lobby`}>ADQUIRIR MEMBRESÍA</AuthButtonLink>
                  <AuthButtonLink to="?modal=insert-code&backModal=lobby" className="last">
                     INGRESAR CÓDIGO DE EVENTO
                  </AuthButtonLink>
               </ContentButtons>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
