import {
   INIT_APP,
   LOGIN,
   LOGIN_SUCCESS,
   LOGIN_ERROR,
   LOG_OUT,
   GET_USERDATA,
   GET_USERDATA_SUCCESS,
   GET_USERDATA_ERROR,
   GET_MEMBERSHIP,
   GET_MEMBERSHIP_SUCCESS,
   GET_MEMBERSHIP_ERROR,
   UPDATE_PROFILE,
   UPDATE_PROFILE_SUCCESS,
   UPDATE_PROFILE_ERROR,
   REGISTER_USER,
   REGISTER_USER_ERROR,
   RENEW_TOKEN,
   PAY_MEMBERSHIP_ERROR,
   PAY_MEMBERSHIP,
   PAY_MEMBERSHIP_SUCCESS,
} from "../Types";

const initialData = {
   loggedIn: false,
   loading: false,
   fetching: false,
};

export default function AuthReducer(state = initialData, action) {
   switch (action.type) {
      // get previous session
      case INIT_APP:
         return {...state, loading: false};
      // login
      case LOGIN:
         return {...state, fetching: true, add: true, error: null};
      case LOGIN_SUCCESS:
         return {...state, loggedIn: true, token: action.payload};
      case LOGIN_ERROR:
         return {...state, fetching: false, error: action.payload};
      case LOG_OUT:
         document.cookie = `token=`;
         return {...initialData};
      case RENEW_TOKEN:
         return {...state, ...action.payload};
      // userdata
      case GET_USERDATA:
         return {...state, fetching: true, error: null};
      case GET_USERDATA_SUCCESS:
         return {...state, fetching: false, ...action.payload};
      case GET_USERDATA_ERROR:
         return {...state, fetching: false, error: action.payload};
      // membership
      case GET_MEMBERSHIP:
         return {...state, fetching: true, error: null};
      case GET_MEMBERSHIP_SUCCESS:
         return {...state, fetching: false, ...action.payload};
      case GET_MEMBERSHIP_ERROR:
         return {...state, fetching: true, error: action.payload};
      case PAY_MEMBERSHIP:
         return {...state, fetchingPay: true};
      case PAY_MEMBERSHIP_SUCCESS:
         return {...state, fetchingPay: false};
      case PAY_MEMBERSHIP_ERROR:
         return {...state, fetchingPay: false, ...action.payload};
      // update profile
      case UPDATE_PROFILE:
         return {...state, fetching: true, error: null, userDataRegister: action.payload};
      case UPDATE_PROFILE_SUCCESS:
         return {...state, fetching: false, ...state.userDataRegister, ...action.payload};
      case UPDATE_PROFILE_ERROR:
         return {...state, fetching: false, error: action.payload};
      case REGISTER_USER:
         return {...state, fetching: true, error: null};
      case REGISTER_USER_ERROR:
         return {...state, fetching: false, error: action.payload};
      default:
         return {...state};
   }
}
