/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AuthButton} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getMembershipStatus} from "../../../ContextProvider/Actions";
import PriceData from "../../../components/PriceData";

export default function PaypalView() {
   const dispatch = useDispatch();
   const {membership, token} = useSelector((state) => state.auth);
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      document.addEventListener("visibilitychange", function () {
         if (document.visibilityState === "visible") {
            dispatch(getMembershipStatus());
         }
      });
      return () => document.removeEventListener("visibilitychange", () => {});
   }, []);

   useEffect(() => {
      if (membership) {
         return history.replace(location.pathname);
      }
   }, [membership]);

   useEffect(() => {}, []);

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace("?modal=pays");
            }}
         >
            <ContentCard>
               <BigText>Pagar con PayPal</BigText>
               <PriceData type="card" />
               <AuthButton
                  as="a"
                  href={`https://trebolbit.mx/pagosFreeticket/index.html?token=${token}&from=freeticketlive`}
                  target="_blanck"
               >
                  Continuar
               </AuthButton>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
