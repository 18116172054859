import React, {useEffect} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Container from "../../../containers/Container";
// import {EventsContext} from "../../../context/EventsProvider";
import {LoaderComponentFixed} from "../../../components/loader/LoaderComponent";
import styled from "styled-components";
import EventComponent from "../../../components/event/EventComponent";
import {useDispatch, useSelector} from "react-redux";
import {getWeekEventsAction} from "../../../ContextProvider/Actions";

export default function EventsList() {
   // const {futureEventList, getFutureEvents} = useContext(EventsContext);
   const dispatch = useDispatch();
   const {weekEvents, fetchingWeek} = useSelector((state) => state.events);

   useEffect(() => {
      dispatch(getWeekEventsAction());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <AnimatedView>
         <Container>
            <ViewContainer> 
               <EventsContainer>{React.Children.toArray(weekEvents.map((event) => <EventComponent data={event} />))}</EventsContainer>
               <LoaderComponentFixed isVisible={fetchingWeek} />
            </ViewContainer>
         </Container>
      </AnimatedView>
   );
}

const ViewContainer = styled.div`
   width: 100%;
   height: 100%;
   position: relative;
   min-height: 300px;
`;
const EventsContainer = styled.div`
   width: 100%;
   display: flex;
   flex-wrap: wrap;

   & > article {
      width: 25%;
      margin-bottom: 2em;
   }

   @media screen and (max-width: 900px) {
      & > article {
         width: 33%;
      }
   }

   @media screen and (max-width: 600px) {
      & > article {
         width: 50%;
      }
   }
`;
