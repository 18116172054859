/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {userDataService, membershipInfo, renewToken} from "../services/AuthServices";

export const GlobalContext = React.createContext();

export default function GlobalProvider({children}) {
   // indica si es VISIT, está logeado pero no puede ver -- restricted -- o sí puede ver -- full -- ; byCode
   const [userType, setUserType] = useState("");
   const [isLogin, setIsLogin] = useState(false);
   const [userProfileData, setUserProfileData] = useState({});
   const [membershipData, setMembershipData] = useState({});
   const [token, setToken] = useState(false);

   useEffect(() => {
      initApp();
   }, []);

   useEffect(() => {
      if (isLogin && token) {
         getProfileData(token);
      }
   }, [isLogin, token]);

   useEffect(() => {
      if (isLogin && token) {
         getUserType();
      }
   }, [userProfileData]);

   const initApp = () => {
      const localToken = localStorage.getItem("freeticket_token");
      // setUserType("visit");
      if (localToken) {
         doGlobalLogin(localToken);
      }
   };

   const doGlobalLogin = (token) => {
      setToken(token);
      localStorage.setItem("freeticket_token", token);
      setIsLogin(true);
   };

   const getUserType = () => {
      const localToken = localStorage.getItem("freeticket_token");
      renewToken(localToken, userProfileData.userId).then((token) => {
         membershipInfo({token, id_user: userProfileData.id})
            .then((res) => res.json())
            .then((resp) => {
               const {status, data} = resp;
               if (status === "error") throw new Error();
               // let neext = !!data.status ? "full" : "restricted";
               setMembershipData(data);
               setUserType("full");
            })
            .catch(() => {
               setUserType("restricted");
            });
      });
   };

   const getProfileData = (token) => {
      userDataService(token)
         .then((res) => res.json())
         .then((resp) => {
            const {status, data} = resp;
            if (status === "error") throw new Error();
            setUserProfileData(data);
         })
         .catch(() => {
            // Es probable que el token caducó
            doLogout();
         });
   };

   const renewUserToken = async () => {
      // si no datos de usuario cancelar la actualizacón y cerrar sesión
      const localToken = localStorage.getItem("freeticket_token");
      let nToken = await renewToken(localToken, userProfileData.id);
      // si el nuevo token marca error cerrar sesión
      if (!nToken) return doLogout();
      localStorage.setItem("freeticket_token", nToken);
      return nToken;
   };

   const doLogout = () => {
      localStorage.removeItem("freeticket_token");
      setToken(false);
      setUserType("visit");
      setIsLogin(false);
      setUserProfileData({});
   };

   return (
      <GlobalContext.Provider
         value={{
            userType, // puede acceder a los video o no
            isLogin, // tiene una sesion iniciada o no
            doGlobalLogin, // para guardar token y establecer sesión
            userProfileData, // datos del perfil de usuario
            renewUserToken, // devuelve un token actulizado
            setUserType, // actualizar tipo de usuario
            getUserType,
            membershipData, //
            doLogout,
            getProfileData,
         }}
      >
         {children}
      </GlobalContext.Provider>
   );
}
