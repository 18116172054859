import React from "react";
import ModalContainer from "../../../containers/ModalContainer";
import CardModalAuth from "../../../containers/CardModalAuth";
import {ContentCard, BigText, AppText} from "../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import PriceData from "../../../components/PriceData";
// import ToggleAnimate from "../../../containers/ToggleAnimate";

export default function TransferPayView() {
   const location = useLocation();
   const history = useHistory();

   return (
      <ModalContainer
         close={() => {
            history.replace(location.pathname);
         }}
      >
         <CardModalAuth
            close={() => {
               history.replace(location.pathname);
            }}
            back={() => {
               history.replace("?modal=pays");
            }}
         >
            <ContentCard>
               <BigText>Adquiere tu membresía</BigText>
               <AppText>Realiza tu transferencia o depósito bancario en:</AppText>
               <PriceData />
               <AppText>
                  Tu cuenta incluye 16 shows en streaming de stand up comedy, tickets para cine, tickets para eventos aliados y tickets para
                  shows en vivo de stand up comedy
               </AppText>
            </ContentCard>
         </CardModalAuth>
      </ModalContainer>
   );
}
