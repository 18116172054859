import React from "react";
import {AboutSection, DownAppSection, ColDown, TextAbout, LogoSection, ExternalLink, LinkCont} from "./AboutStyles";
import AnimateImg from "../../../../containers/AnimateImg";
import Container from "../../../../containers/Container";

export default function DownloadApp() {
   return (
      <AboutSection>
         <Container>
            <DownAppSection>
               <ColDown className="minCol">
                  <LogoSection className="mobile">
                     <AnimateImg src={require("../../../../assets/brand/freeticket_black.png")} alt="freeticket_logo" />
                  </LogoSection>
                  <AnimateImg src={require("../../../../assets/img/mobile_app.png")} className="imgdown" alt="mobile_app_freeticket" />
               </ColDown>
               <ColDown className="maxCol">
                  <LogoSection>
                     <AnimateImg src={require("../../../../assets/brand/freeticket_black.png")} alt="freeticket_logo" />
                  </LogoSection>
                  <TextAbout>
                     Descarga nuestra <b>app</b> y asiste a <b>los mejores shows</b> de comedia en vivo, obten entradas para tus conciertos
                     favoritos, cine y <b>muchos otros eventos</b> que <b>no te puedes perder.</b>
                  </TextAbout>
                  <LinkCont>
                     <TextAbout>Disponible en:</TextAbout>
                     <div className="iconsL">
                        <ExternalLink href="https://apps.apple.com/us/app/free-ticket/id1460338615?l=es" target="_blanck">
                           <AnimateImg src={require("../../../../assets/banner/app_store_icon.png")} className="imgLink" />
                        </ExternalLink> 
                        <ExternalLink
                           href="https://play.google.com/store/apps/details?id=com.emeraldStudio.FreeTicket&hl=es"
                           target="_blanck"
                        >
                           <AnimateImg src={require("../../../../assets/banner/google_play_icon.png")} className="imgLink" />
                        </ExternalLink>
                     </div>
                  </LinkCont>
               </ColDown>
            </DownAppSection>
         </Container>
      </AboutSection>
   );
}
