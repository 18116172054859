import {createStore, combineReducers, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import {connectRouter, routerMiddleware} from "connected-react-router";
import {createBrowserHistory} from "history";
import {AuthReducer, EventsReducer, StreamReducer} from "./Reducer";
import {initAuthAction, recoverLocalStream} from "./Actions";

export const history = createBrowserHistory();

const rootReducer = (history) =>
   combineReducers({
      auth: AuthReducer,
      events: EventsReducer,
      stream: StreamReducer,
      router: connectRouter(history),
   });

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composeEnhancers = compose;

export default function configureStore(preloadedState = {}) {
   const store = createStore(
      rootReducer(history), // root reducer with router state
      preloadedState,
      composeEnhancers(
         applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk,
         ),
      ),
   );
   initAuthAction()(store.dispatch, store.getState);
   recoverLocalStream()(store.dispatch, store.getState);
   return store;
}
