import React from "react";
import {CoverContent, TitleCover, SubtitleCover, TextCover} from "../../styles/HomeStyles";
import styled, {useTheme} from "styled-components";
import {ButtonLink} from "../../styles/ButtonStyles";
import AnimateImg from "../../containers/AnimateImg";

export default function SlideStayHome({background}) {
   const theme = useTheme();
   return (
      <CustomCover stomCover background={"https://appfreeticket.com/static/media/about_background.28bdfda6.jpg"}>
         <AnimateImg className="logoImg" src={require("../../assets/brand/stay_home_comedy.png")} alt="stay_home_comedy" />
         <SubtitleCover className="compress">TRAEMOS LO MEJOR DEL</SubtitleCover>
         <TitleCover color={theme.primary} className="compress">
            STAND-UP EN VIVO
         </TitleCover>
         <TitleCover className="compress max">
            HASTA TU <span style={{color: theme.primary}}>CASA!</span>
         </TitleCover>
         <ButtonLink to="/events">VER EVENTOS</ButtonLink>
      </CustomCover>
   );
}

const CustomCover = styled(CoverContent)`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & .logoImg {
      width: 100px;
      margin-bottom: 0.5em;
   }

   & .compress {
      letter-spacing: -1px;
      font-weight: 600;
      transform: scaleY(1.5);
      text-transform: uppercase;
      margin: 0;
      margin-bottom: 0.1em;
   }

   & ${SubtitleCover} {
      font-size: 1.3em;
      text-align: center;

      @media screen and (max-width: 600px) {
         // mobile
         font-size: 1.1em;
      }
   }

   & ${TitleCover} {
      margin-bottom: 0.3em;
      font-size: 2.1em;

      text-align: center;
      & span {
         font-weight: inherit;
         font-size: inherit;
      }

      &.max {
         font-size: 2.4em;
      }

      @media screen and (max-width: 600px) {
         // mobile
         font-size: 1.4em;
         &.max {
            font-size: 1.6em;
         }
      }
   }

   & ${TextCover} {
      max-width: 50%;
      text-align: center;
      font-size: 0.9em;

      .compress {
         font-size: 1.1em;
      }
   }

   & ${ButtonLink} {
      font-weight: bold;
      padding-left: 2em;
      padding-right: 2em;
      margin-top: 1em;
   }
`;
