import React from "react";
import Container from "../../../containers/Container";
import styled from "styled-components";
import {ButtonLink, Button} from "../../../styles/ButtonStyles";
import {ReactComponent as InstaIcon} from "../../../assets/icons/instagram.svg";
import {ReactComponent as FbIcon} from "../../../assets/icons/facebook.svg";
import {ReactComponent as EmailIcon} from "../../../assets/icons/email.svg";
import {ReactComponent as WhatsIcon} from "../../../assets/icons/whatsapp.svg";

export default function ContactSection() {
   return (
      <Container>
         <ContentStyled>
            <ColFooter>
               <img src={require("../../../assets/brand/freeticket_text_black.png")} alt="freeticket" className="logo" />
            </ColFooter>
            <ColFooterR>
               <Button as="a" href="https://www.instagram.com/freeticketla/" target="_blanck">
                  freeticketla
                  <InstaIcon width="1.5em" height="1.5em" className="icon" />
               </Button>
               <Button as="a" href="https://www.facebook.com/appfreeticket/" target="_blanck">
                  freeticket
                  <FbIcon width="1.5em" height="1.5em" className="icon" />
               </Button>
               <Button as="a" href="mailto:contacto@freeticket.com" target="_blanck">
                  directorcampmx@appfreeticket.com
                  <EmailIcon width="1.5em" height="1.5em" className="icon" />
               </Button>
               <Button as="a" href="https://api.whatsapp.com/send?phone=+5215611954495" target="_blanck">
                  México: 5611954495
                  <WhatsIcon width="1.5em" height="1.5em" className="icon" />
               </Button>
               <Button as="a" href="https://api.whatsapp.com/send?phone=+573004231857" target="_blanck">
                  Colombia: 3004231857
                  <WhatsIcon width="1.5em" height="1.5em" className="icon" />
               </Button>
               <ButtonLink to="?modal=terms">Términos y condiciones</ButtonLink>
               <ButtonLink to="?modal=privacy">Política de privacidad</ButtonLink>
            </ColFooterR>
         </ContentStyled>
      </Container>
   );
}

const ContentStyled = styled.div`
   width: 100%;
   padding: 1em 0;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & .logo {
      width: 95px;
      height: 75px;
      // margin-bottom: 2em;
   }

   @media screen and (max-width: 600px) {
      flex-wrap: wrap;

      & .logo {
         width: 30%;
         margin: 0 auto 1.5em auto;
         object-fit: cover;
      }
   }
`;

const ColFooter = styled.div`
   width: 10%;
   display: flex;
   flex-direction: row;
   margin-bottom: 2em;
   
   @media screen and (max-width: 600px) {
      width: 80%;
      margin-bottom: 1em;
   }
`;

const ColFooterR = styled(ColFooter)`
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   
   & a {
      display: inline-flex;
      align-items: center;
      text-align: right;
      text-decoration: none;
      padding: 0.1em 0;
      margin-bottom: 0.5em;
      width: auto;
      padding-left: 2%;

      & .icon {
         margin-left: 0.5em;
      }
   }

   @media screen and (max-width: 1100px) {
      align-items: center;
      flex-direction: column;
   }
`;
