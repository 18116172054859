import React from "react";
import {ColText, Separator, ContentMember, DataMemberCol, ContentButtons} from "../../../../styles/ProfileStyles";
import {ButtonLink} from "../../../../styles/ButtonStyles";
import {useSelector} from "react-redux";

export default function MembershipInfo() {
   const {membership, membershipData} = useSelector((state) => state.auth);

   return (
      <>
         <ColText style={{marginTop: "0.5em"}}>
            Tu membresía está <b>{membership ? "activa" : "desactivada"}</b>
         </ColText>

         <Separator>
            <div className="bar" />
         </Separator>

         {membership ? (
            <ContentMember>
               <DataMemberCol>
                  <ColText className="bold">Activación</ColText>
                  <ColText>{membershipData.date_acquired.substring(0, 10)}</ColText>
               </DataMemberCol>
               <DataMemberCol>
                  <ColText className="bold">Suscripción</ColText>
                  <ColText>Mensual</ColText>
               </DataMemberCol>
               <DataMemberCol>
                  <ColText className="bold">Valor</ColText>
                  <ColText>8 USD</ColText>
               </DataMemberCol>
            </ContentMember>
         ) : (
            <ContentButtons>
               <ButtonLink to="?modal=pays">ADQUIRIR MEMBRESÍA</ButtonLink>
            </ContentButtons>
         )}
      </>
   );
}
