import React, {useState} from "react";
import Container from "../../containers/Container";
import {NavBar, ItemNav, ContentNavStyle} from "../../styles/HeaderStyles";
import {ButtonLink, Button} from "../../styles/ButtonStyles";
import {ReactComponent as BurguerIcon} from "../../assets/icons/burguer.svg";
import {theme} from "../../constants/Theme";
import MenuMobile from "../menu/MenuMobile";
import {useSelector} from "react-redux";

export default function Nav() {
   return (
      <NavBar>
         <Container className="navContent">
            <ContentNav />
         </Container>
      </NavBar>
   );
}

export const ContentNav = () => {
   const [isOpen, setOpen] = useState(false);

   return (
      <>
         <ContentNavStyle>
            <div>
               <ItemNav to="/" className="logoNav">
                  <img src={require("../../assets/brand/freeticket_text_yellow.png")} alt="freeticket" />
               </ItemNav>
            </div>
            <div className="deskOption">
               <ItemNav to="/">Inicio</ItemNav>
               <ItemNav to="/about">Acerca de</ItemNav>
               <ItemNav to="/events">Eventos</ItemNav>
               <ProfileButton setOpen={setOpen} />
            </div>
            <Button
               className="mobileOption"
               onClick={() => {
                  setOpen(true);
               }}
            >
               <BurguerIcon width="1.5em" height="1.5em" fill={theme.primary} />
            </Button>
         </ContentNavStyle>
         <MenuMobile
            isOpen={isOpen}
            close={() => {
               setOpen(false);
            }}
         />
      </>
   );
};

const ProfileButton = ({setOpen}) => {
   const {loggedIn} = useSelector((state) => state.auth);

   if (loggedIn)
      return (
         <ButtonLink
            to="/profile"
            onClick={() => {
               setOpen(false);
            }}
            className="onlineButton"
         >
            PERFIL
         </ButtonLink>
      );

   return (
      <ButtonLink
         to="?modal=login"
         onClick={() => {
            setOpen(false);
         }}
         className="onlineButton"
      >
         LOG IN
      </ButtonLink>
   );
};
