import styled from "styled-components";
import Container from "../containers/Container";

export const CoverContent = styled(Container)`
   height: 100%;
   width: 100%;
   background-image: url(${(props) => props.background});
   background-size: cover;
   background-position: center;
   padding: 100px 5%;

   & .CoverChild {
      width: 40%;
   }

   & .callAction {
      width: 250px;
      margin-top: 1em;
   }

   @media screen and (max-width: 1100px) {
      // table
      & .CoverChild {
         width: 70%;
      }
   }
   @media screen and (max-width: 800px) {
      // table
      & .CoverChild {
         width: 60%;
      }
   }
   @media screen and (max-width: 600px) {
      // mobile

      & .CoverChild {
         width: 100%;
      }
      & .callAction {
         width: 60%;
         margin: 0 auto;
         margin-top: 1.5em;
      }
   }
`;

export const TitleCover = styled.h1`
   font-size: 2.5em;
   font-weight: 400;
   color: ${(props) => props.color || ""};

   @media screen and (max-width: 600px) {
      // mobile
      font-size: 1.5em;
      margin-bottom: 0.1em;
   }
`;
export const SubtitleCover = styled.h3`
   font-size: 2em;
   font-weight: 300;
   margin-bottom: 0.2em;

   @media screen and (max-width: 600px) {
      // mobile
      font-size: 1.5em;
      margin-bottom: 0.5em;
   }
`;
export const TextCover = styled.p`
   font-size: 1em;
   font-weight: 300;
   margin-bottom: 0.5em;

   @media screen and (max-width: 600px) {
      // mobile
      padding-right: 0;
   }
`;
