/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {Separator, InfoData, TitleData, HeadData, DescriptionData, ColDate, CoverEvent} from "../../../styles/StreamStyles";
import {useParams, useHistory} from "react-router-dom";
import {ReactComponent as CalendarIcon} from "../../../assets/icons/calendar.svg";
import {ReactComponent as ClockIcon} from "../../../assets/icons/clock.svg";
import {AppLoader} from "../../../components/loader/AppLoader";
import AnimatedView from "../../../containers/AnimatedView";
import Container from "../../../containers/Container";
import Header from "../../../components/header/Header";
import AnimateImg from "../../../containers/AnimateImg";
import VideoPlayer from "../video_player/VideoPlayer";
import CarouselToday from "../../../containers/CarouselToday";
import {useDispatch, useSelector} from "react-redux";
import {getEventDataAction} from "../../../ContextProvider/Actions";
import {hostUrl} from "../../../services/ApiUrl";

export default function StreamView() {
   const {loggedIn} = useSelector((state) => state.auth);
   const {streamEvent, fetching} = useSelector((state) => state.events);
   const dispatch = useDispatch();
   const [isActive, setIsActive] = useState(false);
   const {id} = useParams();
   const history = useHistory();

   useEffect(() => {
      window.scrollTo({top: 0});
   }, []);

   useEffect(() => {
      dispatch(getEventDataAction(id));
   }, [id]);

   useEffect(() => {
      setIsActive(!!streamEvent.active);
   }, [streamEvent]);

   useEffect(() => {
      if (!loggedIn & isActive) {
         return history.push("?modal=login");
      }
   }, [loggedIn, isActive]);

   return (
      <>
         <AnimatedView showNav={false}>
            <Container>
               <Header />
               {fetching ? (
                  <AppLoader isVisible={true} />
               ) : (
                  <>
                     {isActive && loggedIn ? (
                        <VideoPlayer />
                     ) : (
                        <CoverEvent>
                           <AnimateImg src={hostUrl + streamEvent.preview_img} alt="img" className="imgCoverEvent" />
                        </CoverEvent>
                     )}

                     <InfoData>
                        <HeadData>
                           <TitleData className="bold">Nombre del evento</TitleData>
                           <ColDate>
                              <div className="row">
                                 <CalendarIcon fill="white" width="2em" height="2em" className="IconDate" />
                                 <p className="infoDate">{streamEvent.date_event}</p>
                              </div>
                              <div className="row">
                                 <ClockIcon fill="white" width="2em" height="2em" className="IconDate" />
                                 {/* <p className="infoDate">{streamEvent.time_start.substring(0, 5)}</p> */}
                              </div>
                           </ColDate>
                        </HeadData>
                        <TitleData>{streamEvent.title}</TitleData>
                        <DescriptionData>{streamEvent.description}</DescriptionData>
                        <img src={require("../../../assets/icons/down_bread.png")} alt="" className="downIcon" />
                     </InfoData>
                  </>
               )}
            </Container>
            <CarouselToday />
            <Separator />
         </AnimatedView>
      </>
   );
}
