import React, {useEffect} from "react";
import Input from "../../../../components/auth/Input";
import {AuthButton, StyledForm, AppText} from "../../../../styles/AuthStyles";
import {useLocation, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {renewTokenAction, payMembershipAction} from "../../../../ContextProvider/Actions";

export default function PayWithStripe({propsToChild}) {
   const {payError, membership} = useSelector((state) => state.auth);
   const dispatch = useDispatch();
   const location = useLocation();
   const history = useHistory();

   useEffect(() => {
      dispatch(renewTokenAction());
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      if (membership) {
         history.replace(location.pathname);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [membership]);

   const pay = (e) => {
      e.preventDefault();
      let toSend = {
         number: e.target.number_card.value,
         exp_year: e.target.year.value.length === 2 ? Number(20 + "" + e.target.year.value) : Number(e.target.year.value),
         exp_month: Number(e.target.month.value),
         cvc: e.target.cvc.value,
         name: e.target.name.value,
         email: e.target.email.value,
         description: "",
      };
      dispatch(payMembershipAction(toSend));
   };

   return (
      <>
         <StyledForm onSubmit={pay}>
            {React.Children.toArray(
               InputList.map((newInput) => {
                  return (
                     <Input
                        type={newInput.type}
                        inputConfig={newInput.inputConfig}
                        name={newInput.name}
                        placeholder={newInput.placeholder}
                        required={true}
                     />
                  );
               }),
            )}
            <label className="labelTitle">Fecha de Vencimiento</label>
            {React.Children.toArray(
               InputListB.map((newInput) => {
                  return (
                     <Input
                        type={newInput.type}
                        name={newInput.name}
                        placeholder={newInput.placeholder}
                        className={newInput.classname ? newInput.classname : ""}
                        required={true}
                        inputConfig={newInput.inputConfig}
                     />
                  );
               }),
            )}
            {payError && <AppText color="crimson">{payError}</AppText>}
            <AuthButton>PAGAR CON STRIPE</AuthButton>
         </StyledForm>
      </>
   );
}

const InputList = [
   {
      name: "name",
      type: "text",
      placeholder: "Nombre del títular",
   },
   {
      name: "email",
      type: "email",
      placeholder: "Correo electrónico",
   },
   {
      name: "number_card",
      type: "number",
      placeholder: "Número de tarjeta",
      inputConfig: {maxLength: 16, minLength: 13},
   },
];

const InputListB = [
   {
      name: "month",
      type: "number",
      placeholder: "Mes (2 dígitos)",
      inputConfig: {max: 12, min: 1},
      classname: "min",
   },
   {
      name: "year",
      type: "number",
      placeholder: "Año (2 dígitos)",
      inputConfig: {max: 30, min: 20},
      classname: "min",
   },
   {
      name: "cvc",
      type: "number",
      placeholder: "CVC",
   },
];
