/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import AnimatedView from "../../../containers/AnimatedView";
import Cover from "../../../components/cover/Cover";
import WeAre from "./sectors/WeAre";
import OurOffer from "./sectors/OurOffer";
import DownloadApp from "./sectors/DownloadApp";
import DiscoverCalendar from "./sectors/DiscoverCalendar";
import {useLocation} from "react-router-dom";
import {ContentCover, TitleCover, ColLeft, TextCover} from "./sectors/AboutStyles";
import usePrevious from "../../../hooks/usePrevious";
import AnimateImg from "../../../containers/AnimateImg";

export default function AboutView() {
   let location = useLocation();
   let prevLocation = usePrevious(location);

   useEffect(() => {
      moveTo();
   }, [location]);

   const moveTo = () => {
      if (prevLocation) {
         if (prevLocation.pathname === location.pathname) return false;
      }
      window.scrollTo({top: 0});
   };
   return (
      <AnimatedView showNav={false}>
         <Cover background={require("../../../assets/img/about_background.jpg")}>
            <ContentCover>
               <ColLeft>
                  <TitleCover>
                     QUEDARTE EN <span>CASA</span>
                     <br /> AHORA ES MÁS DIVERTIDO
                  </TitleCover>
                  <TextCover>
                     Tus eventos favoritos ahora en vivo desde nuestra plataforma de streaming. <br />
                     Registrate y disfruta de los mejores shows y presentaciones desde la comodidad de tu hogar. <br />
                     <br />
                     Tu membresía incluye 16 shows en streaming de stand up comedy, tickets para cine, tickets para eventos aliados y
                     tickets para shows en vivo de stand up comedy.
                  </TextCover>
               </ColLeft>
               <ColLeft className="imgAboutContent">
                  <AnimateImg src={require("../../../assets/icons/comedy_icon.png")} className="imgAbout" />
               </ColLeft>
            </ContentCover>
         </Cover>
         <WeAre />
         <OurOffer />
         <DownloadApp />
         <DiscoverCalendar />
      </AnimatedView>
   );
}
