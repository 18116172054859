import React from "react";

class Player extends React.Component {
   _player = null;
   _el = null;

   componentDidMount() {
      // props like the source, or the sourcedesciption as a whole can be passed through
      // the can then be used to be called on the player itself
      const {source} = this.props;

      if (!source) return false;

      if (this._el) {
         this._player = new window.THEOplayer.Player(this._el, {
            libraryLocation: "https://cdn.myth.theoplayer.com/9771a883-5b7f-4eaa-b82c-5eb244899138",
         });

         this._player.source = {
            sources: [
               {
                  src: source,
                  type: "video/mp4",
               },
               /* {
                  src: "https://www.radiantmediaplayer.com/media/bbb-360p.mp4",
                 type: "video/mp4",
               }, */
            ],
            // Advertisement configuration
         };
      }
   }

   render() {
      if (!this.props.source) return false;
      return (
         <div
            // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
            className={"theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer"}
            // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
            // Which is need by the player
            ref={(el) => (this._el = el)}
         />
      );
   }
}

export default Player;
