import React from "react";
import Input from "../../../../components/auth/Input";
import {AuthButton, StyledForm} from "../../../../styles/AuthStyles";
// import {ePayCoService} from "../../../../services/PayServices";

export default function PayWithEpayCo({propsToChild}) {
   const pay = (e) => {
      e.preventDefault();
      propsToChild.setLoading(true);

      /* let toSend = {
         plan_id: "freeticket",
         // card
         number: Number(e.target.number_card_epayco.value),
         year: Number(20 + "" + e.target.year_epayco.value),
         month: Number(e.target.month_epayco.value),
         cvc: Number(e.target.cvc_epayco.value),
         // user
         name: e.target.first_name_epayco.value,
         last_name: e.target.last_name_epayco.value,
         // contact
         email: e.target.email_epayco.value,
         city: e.target.city_epayco.value,
         address: e.target.address_epayco.value,
         phone: e.target.phone_epayco.value,
         // document
         doc_type: "CC",
         doc_number: 10358519,
      }; */

      /*  renewUserToken().then((token) => {
         // token renovado
         toSend = {...toSend, token};

         ePayCoService(toSend)
            .then((res) => res.json())
            .then((resp) => {
               const {status} = resp;
               if (status === "error") throw new Error();
               propsToChild.setLoading(false);
            })
            .catch(() => {});
      }); */
   };

   return (
      <>
         <StyledForm onSubmit={pay}>
            {React.Children.toArray(
               InputList.map((newInput) => {
                  return (
                     <Input
                        type={newInput.type}
                        name={newInput.name + "_epayco"}
                        placeholder={newInput.placeholder}
                        className={newInput.classname ? newInput.classname : ""}
                        required={true}
                        inputConfig={newInput.inputConfig}
                     />
                  );
               }),
            )}
            <label className="labelTitle">Fecha de Vencimiento</label>
            {React.Children.toArray(
               InputListB.map((newInput) => {
                  return (
                     <Input
                        type={newInput.type}
                        name={newInput.name + "_epayco"}
                        placeholder={newInput.placeholder}
                        className={newInput.classname ? newInput.classname : ""}
                        required={true}
                        inputConfig={newInput.inputConfig}
                     />
                  );
               }),
            )}
            <AuthButton>PAGAR CON ePayCo</AuthButton>
         </StyledForm>
      </>
   );
}

const InputList = [
   {
      name: "first_name",
      type: "text",
      placeholder: "Nombre del títular",
      classname: "min",
   },
   {
      name: "last_name",
      type: "text",
      placeholder: "Apellidos",
      classname: "min",
   },
   {
      name: "email",
      type: "email",
      placeholder: "Correo electrónico",
   },
   {
      name: "number_card",
      placeholder: "Número de tarjeta",
      type: "number",
      inputConfig: {maxLength: 16, minLength: 13},
   },
];

const InputListB = [
   {
      name: "month",
      type: "number",
      placeholder: "Mes",
      inputConfig: {max: 12, min: 1},
      classname: "min",
   },
   {
      name: "year",
      type: "number",
      placeholder: "Año",
      inputConfig: {max: 30, min: 20},
      classname: "min",
   },
   {
      name: "cvc",
      type: "number",
      placeholder: "CVC",
      inputConfig: {max: 9999},
   },
   {
      name: "city",
      type: "text",
      placeholder: "Ciudad",
   },
   {
      name: "address",
      type: "text",
      placeholder: "Dirección",
   },
   {
      name: "phone",
      type: "tel",
      placeholder: "Teléfono",
   },
   {
      name: "doc_number",
      type: "text",
      placeholder: "Cédula de ciudadania",
   },
];
