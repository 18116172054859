import React, {useContext, useState} from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import SlideCenter from "../../../components/slides/SlideCenter";
import SlideStayHome from "../../../components/slides/SlideStayHome";
import {EventsContext} from "../../../context/EventsProvider";
import {useEffect} from "react";

const responsive = {
   superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: {max: 4000, min: 0},
      items: 1,
   },
};

export default function CarouselHome() {
   const {getFutureEvents, futureEventList, todayEventsList, getTodayEvents} = useContext(EventsContext);
   const [listSlides, setSlides] = useState([]);

   useEffect(() => {
      createSlides();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [futureEventList]);

   useEffect(() => {
      getFutureEvents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [todayEventsList]);

   useEffect(() => {
      getTodayEvents();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const createSlides = () => {
      let eventsSlides = [];
      let activeEvent = todayEventsList.data?.[0];
      let nextEvent = futureEventList.data[0];
      if (activeEvent) {
         eventsSlides = [...eventsSlides, {...activeEvent, type: "center"}];
      } else {
         let second = futureEventList.data[1];
         if (second) {
            eventsSlides = [...eventsSlides, {...second, type: "center"}];
         }
      }
      if (nextEvent) {
         eventsSlides = [...eventsSlides, {...nextEvent, type: "center"}];
      }
      eventsSlides = [...eventsSlides, {type: "stay"}];
      setSlides(eventsSlides);
   };

   return (
      <Carousel
         showDots={false}
         responsive={responsive}
         infinite={true}
         keyBoardControl={true}
         scontainerClass="carousel-container"
         dotListClass="custom-dot-list-style"
         itemClass="carousel-item-padding-40-px"
      >
         {React.Children.toArray(
            listSlides.map((item) => { 
               if (item.type === "stay") return <SlideStayHome />;
               return <SlideCenter data={item} />;
            }),
         )} 
      </Carousel>
   );
}
