import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTransition, animated} from "react-spring";
import usePrevious from "../hooks/usePrevious";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Container from "./Container";
import ModalsRouter from "../router/ModalsRouter";

const AnimatedView = React.forwardRef((props, ref) => {
   let {children, showNav} = props;
   const location = useLocation();
   const [show, set] = useState(true);
   const prevPath = usePrevious(location.pathname); // guardar pathname anterior

   const transitions = useTransition(show, null, {
      from: {opacity: 0},
      enter: {opacity: 1},
      leave: {opacity: 0},
   });

   useEffect(() => {
      if (prevPath === location.pathname) {
         // si es igual activar vista al instante
         // para evitar animación
         set(true);
      } else {
         // si es diferene activar vista con retraso
         // para lograr la animación
         setTimeout(() => {
            set(true);
         }, 10);
      }

      return () => {
         set(false);
      };
   }, [location, prevPath]);

   return (
      <>
         {showNav && (
            <Container>
               <Header />
            </Container>
         )}
         {transitions.map(
            ({item, key, props}) =>
               item && (
                  <animated.div key={key} style={props} ref={ref}>
                     <>{children}</>
                  </animated.div>
               ),
         )}

         <Footer />
         <ModalsRouter />
      </>
   );
});

AnimatedView.defaultProps = {
   showNav: true,
};

export default AnimatedView;
