import styled from "styled-components";

export const StreamCard = styled.div`
   width: auto;
   min-width: 30vw;
   background: ${(props) => props.theme.background};
   height: 80vh;

   & .closeBtn {
      background: transparent;
      outline: none;
      border: none;
      width: 2em;
      heigh: 2em;
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      cursor: pointer;

      & .closeIcon {
         fill: rgba(150, 150, 150, 0.7);
      }
   }
`;

export const StreamViewContent = styled.div`
   width: 100%;
   position: relative;
   height: 70vh;

   &.noHeight {
      height: auto;
      min-height: 200px;
   }
`;

export const HideVideo = styled.div`
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.8);
`;

export const InfoData = styled.div`
   width: 100%;
   paddig: 1em 0;

   & .downIcon {
      display: block;
      margin: 0 auto;
      margin-top: 2em;
      width: 2em;
   }
`;
export const TitleData = styled.p`
   font-size: 3em;

   &.bold {
      font-weight: 600;
   }

   @media screen and (max-width: 900px) {
      font-size: 1.5em;
      line-height: 1.7em;
   }

   @media screen and (max-width: 600px) {
      font-size: 2em;
      line-height: 2.1em;
   }
`;

export const HeadData = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;

   @media screen and (max-width: 600px) {
      align-items: flex-start;
      flex-direction: column-reverse;
   }
`;
export const DescriptionData = styled.p`
   font-size: 1.4em;
   line-height: 1.5em;
   margin-top: 1.5em;

   @media screen and (max-width: 600px) {
      font-size: 1.2em;
      line-height: 1.3em;
   }
`;

export const Separator = styled.div`
   width: 100%;
   padding: 2em 0;
`;

export const TimeEndStyle = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

   & p {
      width: 50%;
      margin-bottom: 1em;
      text-align: center;
   }
`;

export const ColDate = styled.div`
   display: flex;

   & .row {
      display: flex;
      align-items: center;
      margin-left: 0.5em;

      & .infoDate {
         margin-left: 0.5em;
         font-size: 1.5em;
         font-weight: bold;
      }
   }

   @media screen and (max-width: 600px) {
      & .row {
         & .IconDate {
            width: 1em;
            height: 1em;
         }
         & .infoDate {
            font-size: 0.8em;
         }
      }
   }
`;

export const CoverEvent = styled.div`
   width: 100%;
   margin: 2em 0;
   height: 65vh;

   & .imgCoverEvent {
      width: 100%;
      height: 100%;

      & img {
         object-fit: cover;
         height: 100%;
      }
   }
`;
